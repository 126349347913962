
// import React, { useState } from 'react';
// import { Link } from "react-router-dom";
// import styles from '../home.module.css';
// import { 
//   FiCode, 
//   FiSearch, 
//   FiUploadCloud, 
//   FiBarChart2, 
//   FiLink,
//   FiMenu,
//   FiX,
//   FiHome
// } from 'react-icons/fi';
// const NavBar = (props) => {
//   const [menuOpen, setMenuOpen] = useState(false);
 

//   return (
//     <header className={styles.header}>
//       <div className={styles.headerContainer}>
//         <h1 className={styles.pageTitle}>
//         <div className={styles.company-name}>
//       <span className={styles.novum360}>NOVUM<span className={styles.highlight}>360</span></span>
//     </div>
//           <span className={styles.titleGradient}>{props.title}</span>
//         </h1>
        
//         <nav className={styles.navbar}>
//           {/* Mobile menu toggle */}
//           <button 
//             className={styles.mobileMenuButton}
//             onClick={() => setMenuOpen(!menuOpen)}
//             aria-label="Toggle menu"
//             aria-expanded={menuOpen}
//           >
//             {menuOpen ? (
//               <FiX className={styles.menuIcon} />
//             ) : (
//               <FiMenu className={styles.menuIcon} />
//             )}
//           </button>
          
//           {/* Complete Navigation Menu */}
//           <ul className={`${styles.navMenu} ${menuOpen ? styles.active : ''}`}>
//             <li className={styles.navItem}>
//               <Link 
//                 to="/" 
//                 className={styles.navLink}
//                 onClick={() => setMenuOpen(false)}
//               >
//                 <FiHome className={styles.navIcon} />
//                 <span>Home</span>
//               </Link>
//             </li>
            
//             <li className={styles.navItem}>
//               <Link 
//                 to="/script" 
//                 className={styles.navLink}
//                 onClick={() => setMenuOpen(false)}
//               >
//                 <FiCode className={styles.navIcon} />
//                 <span>Ad Unit</span>
//               </Link>
//             </li>
            
//             <li className={styles.navItem}>
//               <Link 
//                 to="/search" 
//                 className={styles.navLink}
//                 onClick={() => setMenuOpen(false)}
//               >
//                 <FiSearch className={styles.navIcon} />
//                 <span>Search</span>
//               </Link>
//             </li>
            
//             <li className={styles.navItem}>
//               <Link 
//                 to="/ad-upload" 
//                 className={styles.navLink}
//                 onClick={() => setMenuOpen(false)}
//               >
//                 <FiUploadCloud className={styles.navIcon} />
//                 <span>Ad Upload</span>
//               </Link>
//             </li>
            
//             <li className={styles.navItem}>
//               <Link 
//                 to="/dashboard" 
//                 className={styles.navLink}
//                 onClick={() => setMenuOpen(false)}
//               >
//                 <FiBarChart2 className={styles.navIcon} />
//                 <span>Dashboard</span>
//               </Link>
//             </li>
            
//             <li className={styles.navItem}>
//               <Link 
//                 to="/page-url-dashboard" 
//                 className={styles.navLink}
//                 onClick={() => setMenuOpen(false)}
//               >
//                 <FiLink className={styles.navIcon} />
//                 <span>URL Dashboard</span>
//               </Link>
//             </li>
//           </ul>
//         </nav>
//       </div>
//     </header>
//   );
// };

// export default NavBar;


import React, { useState } from 'react';
import { Link } from "react-router-dom";
import styles from '../home.module.css';
import { 
  FiCode, 
  FiSearch, 
  FiUploadCloud, 
  FiBarChart2, 
  FiLink,
  FiMenu,
  FiX,
  FiHome
} from 'react-icons/fi';

const NavBar = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className={styles.header}>
      <div className={styles.headerContainer}>
        {/* Company Name - Moved before pageTitle and outside of it */}
        <div className={styles.companyNameContainer}>
          <span className={styles.novumBrand}>Ad<span className={styles.brandAccent}>360</span></span>
        </div>
        
        <h1 className={styles.pageTitle}>
          <span className={styles.titleGradient}>{props.title}</span>
        </h1>
        
        <nav className={styles.navbar}>
          {/* Mobile menu toggle */}
          <button 
            className={styles.mobileMenuButton}
            onClick={() => setMenuOpen(!menuOpen)}
            aria-label="Toggle menu"
            aria-expanded={menuOpen}
          >
            {menuOpen ? (
              <FiX className={styles.menuIcon} />
            ) : (
              <FiMenu className={styles.menuIcon} />
            )}
          </button>
          
          {/* Navigation Menu (unchanged) */}
          <ul className={`${styles.navMenu} ${menuOpen ? styles.active : ''}`}>
            {/* ... your existing menu items ... */}
            <li className={styles.navItem}>
              <Link 
                 to="/" 
                 className={styles.navLink}
                 onClick={() => setMenuOpen(false)}
               >
                 <FiHome className={styles.navIcon} />
                 <span>Home</span>
               </Link>
             </li>
            
             <li className={styles.navItem}>
               <Link 
                 to="/script" 
                 className={styles.navLink}
                 onClick={() => setMenuOpen(false)}
               >
                 <FiCode className={styles.navIcon} />
                 <span>Ad Unit</span>
               </Link>
             </li>
            
             <li className={styles.navItem}>
                          <Link 
                 to="/search" 
                 className={styles.navLink}
                 onClick={() => setMenuOpen(false)}
               >
                 <FiSearch className={styles.navIcon} />
                 <span>Search</span>
               </Link>
             </li>
            
             <li className={styles.navItem}>
               <Link 
                 to="/ad-upload" 
                 className={styles.navLink}
                 onClick={() => setMenuOpen(false)}
               >
                 <FiUploadCloud className={styles.navIcon} />
                 <span>Ad Upload</span>
               </Link>
             </li>
            
             <li className={styles.navItem}>
               <Link 
                 to="/dashboard" 
                 className={styles.navLink}
                 onClick={() => setMenuOpen(false)}
               >
                 <FiBarChart2 className={styles.navIcon} />
                 <span>Dashboard</span>
               </Link>
             </li>
            
             <li className={styles.navItem}>
               <Link 
                 to="/page-url-dashboard" 
                className={styles.navLink}
               onClick={() => setMenuOpen(false)}
               >
                 <FiLink className={styles.navIcon} />
                 <span>URL Dashboard</span>
               </Link>
             </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default NavBar;