import React, { useState, useEffect } from "react";
import axios from "axios";
import { FiCopy, FiDownload } from "react-icons/fi";
import NavBar from '../components/Navbar.js';
import styles from '../Scriptpage.module.css';
import { v4 as uuidv4 } from 'uuid';
import ScriptHistoryTable from './ScriptHistory.jsx'

const ITEMS_PER_PAGE = 5;

const ScriptPage = () => {
    const [domain, setDomain] = useState("");
    const [generatedScript, setGeneratedScript] = useState("");
    const [error, setError] = useState(null);
    const [scriptHistory, setScriptHistory] = useState([]);
    const [isGenerating, setIsGenerating] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const fetchRecentScripts = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/recent-scripts?limit=50`
                );
                const formattedHistory = response.data.map((script) => ({
                    domain: script.domain,
                    script: script.scriptContent,
                    timestamp: script.createdAt || new Date(),
                    id: script.script_id || Date.now()
                }));
                setScriptHistory(formattedHistory);
            } catch (err) {
                console.error("Failed to fetch recent scripts:", err);
            }
        };
        fetchRecentScripts();
    }, []);

    const handleGenerateScript = async () => {
        setError(null);
        if (!domain.trim()) {
            setError("Please enter a valid domain name");
            return;
        }
        const isDomainExists = scriptHistory.some(script => script.domain === domain);
        if (isDomainExists) {
            setError("Domain is already present. Please enter a different domain.");
            return;
        }

        setIsGenerating(true);
        try {
            const script_id = uuidv4();
            const scriptContent = `<div id="ad-${script_id}" class="add">Loading Ad...</div>\n` +
                `<script src="${process.env.REACT_APP_API_URL}/js/combinedScript.js" ` +
                `data-domain="${domain}" data-ad-id="${script_id}"></script>`;

            setGeneratedScript(scriptContent);

            await axios.post(`${process.env.REACT_APP_API_URL}/save-script`, {
                domain,
                scriptContent,
                script_id
            });

            setScriptHistory(prev => [
                { domain, script: scriptContent, timestamp: new Date(), id: Date.now() },
                ...prev
            ]);

        } catch (err) {
            setError("Error generating script. Please try again.");
        } finally {
            setIsGenerating(false);
        }
    };

    const handleCopyScript = () => {
        navigator.clipboard.writeText(generatedScript).then(() => {
            alert("Script copied to clipboard!");
        }).catch(() => {
            alert("Failed to copy script.");
        });
    };

    const handleDownloadScript = () => {
        const blob = new Blob([generatedScript], { type: "text/plain" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "tracking-script.txt";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };



    // Reset pagination when search query changes
    useEffect(() => {
        setCurrentPage(1);
    }, [searchQuery]);


    return (
        <>
            <NavBar title="Ad Unit" />
            <div className={styles.scriptPage}>
                <div className={styles.scriptGeneratorCard}>
                    <h2 className={styles.pageTitle}>
                        <span className={styles.gradientText}>Ad Tracking Script Generator</span>
                    </h2>

                    <div className={styles.inputContainer}>
                        <input
                            type="text"
                            placeholder="Enter domain (example.com)"
                            value={domain}
                            onChange={(e) => setDomain(e.target.value)}
                            className={styles.domainInput}
                        />
                        <button
                            onClick={handleGenerateScript}
                            className={`${styles.ctaButton} ${styles.generateBtn}`}
                            disabled={isGenerating}
                        >
                            {isGenerating ? <span className={styles.spinner}></span> : "Generate"}
                        </button>
                        {error && <p className={styles.errorMessage}>{error}</p>}
                    </div>

                    {generatedScript && (
                        <div className={styles.resultContainer}>
                            <div className={styles.resultHeader}>
                                <h3>Your Tracking Script</h3>
                                <div className={styles.actionButtons}>
                                    <button className={styles.actionBtn} onClick={handleCopyScript}>
                                        <FiCopy />
                                        Copy
                                    </button>
                                    <button className={styles.actionBtn} onClick={handleDownloadScript}>
                                        <FiDownload />
                                        Download
                                    </button>
                                </div>
                            </div>
                            <pre className={styles.scriptBox}>
                                <code>{generatedScript}</code>
                            </pre>
                        </div>
                    )}
                </div>

                {scriptHistory.length > 0 && (
                    <ScriptHistoryTable scripts={scriptHistory} />
                )}
            </div>
        </>
    );
};

export default ScriptPage;
