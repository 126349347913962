import React from 'react';
import { useState, useEffect, useMemo } from 'react';
import Modal from 'react-modal';
import DataTable from 'react-data-table-component';
import AdEditorPopup from './AdEditorPopup.jsx';
import styles from '../AdsGrid.module.css';
import { v4 as uuidv4 } from 'uuid'
import AdDistributionModal from './AdStats.jsx'

const modalStyles = {
  overlay: {
    zIndex: 1000,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    width: '90%',
    maxWidth: '1200px',
    maxHeight: '90vh',
    border: 'none',
    borderRadius: '8px',
    padding: '0',
    overflow: 'auto', // <-- change this from 'hidden' to 'auto'
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
  }
};

Modal.setAppElement('#root');
const AdsGridModal = ({ isOpen, onClose, campaignName }) => {
  const [ads, setAds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAdEditor, setShowAdEditor] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [editingAd, setEditingAd] = useState(null);
  const [isAdEditorOpen, setIsAdEditorOpen] = useState(false);
  const [adstat, setAdStat] = useState(false)
  const [selectedAdId, setSelectedAdId] = useState(null);
  const [updateAd, setUpdateAd] = useState(false);
  
  const fetchAds = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/get-campaign-ads/${campaignName}/ads`
      );

      if (!response.ok) {
        throw new Error('Failed to fetch ads');
      }
      const data = await response.json();
      setAds(data.ads || []);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {

    if (isOpen && campaignName) {
      fetchAds();
    }
  }, [isOpen, campaignName]);

  const handleSaveAd = async (adData) => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("ad_id", adData.ad_id || uuidv4());
      formDataToSend.append("keywords", JSON.stringify(adData.keywords));
      formDataToSend.append("abstract", adData.abstract);
      formDataToSend.append("score", adData.score);
      formDataToSend.append("imageFile", adData.imageFile);
      formDataToSend.append("campaignName", adData.campaignName);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/save-ads`, {
        method: 'POST',
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error('Failed to save the ad');
      }

      const result = await response.json();
      if (editingAd) {
        setAds(ads.map(ad => ad.ad_id === editingAd.ad_id ? result : ad));
      } else {
        setAds([...ads, result]);
      }
      setIsAdEditorOpen(false);
      setEditingAd(null);
    } catch (error) {
      console.error('Error saving ad:', error);
      alert('There was an error saving the ad.');
    }
  };


  const handleDeleteAd = async (campaignName,adId) => {
    if (window.confirm('Are you sure you want to delete this ad?')) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/delete-ad`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json', // Ensure you're sending JSON
          },
          body: JSON.stringify({ campaignName, adId }) // Send the data as JSON in the request body
        });
  
        // Check if the response was successful (status code 200)
        if (!response.ok) {
          // Handle the case where the request fails (e.g., 404, 500)
          throw new Error('Request failed with status: ' + response);
        }
  
        // Parse the response as JSON
        const data = await response.json();
  
        if (data.message) {
          console.log('Success:', data.message);
          
              fetchAds();
            
          
          alert("deleted successfully") // Success message from the server
        } else {
          alert("not deleted ")
          console.log('Error:', data.error); // Error message from the server
        }
      } catch (error) {
        // Catch any errors (network errors, request errors)
        console.error('Request failed:', error);
        alert("not deleted successfully")
      }
    }
  };
  

  const handleStates = async (adId) => {
    setSelectedAdId(adId);
    setAdStat(true)

  }

  const filteredAds = ads.filter(item =>
    (item.abstract && item.abstract.toLowerCase().includes(filterText.toLowerCase())) ||
    (item.keywords && item.keywords.some(keyword =>
      keyword.toLowerCase().includes(filterText.toLowerCase())))
  );


  const columns = [
    {
      name: 'Image',
      selector: row => row.imageUrl,
      cell: row => (
        <div className={styles.imageCell}>

          <img
            src={`${process.env.REACT_APP_API_URL}${row.imageUrl}` || '/fallback-ad-image.jpg'}
            alt="Ad"
            className={styles.tableImage}
            onError={(e) => {
              e.target.src = '/fallback-ad-image.jpg';
            }}
          />
        </div>
      ),
      width: '120px'
    },
    {
      name: 'Abstract',
      selector: row => row.abstract,
      sortable: true,
      cell: row => <div className={styles.abstractCell}>{row.abstract || 'No abstract'}</div>,
      wrap: true
    },
    {
      name: 'Keywords',
      selector: row => row.keywords,
      cell: row => (
        <div className={styles.keywordsCell}>
          {row.keywords?.map((keyword, i) => (
            <span key={i} className={styles.keywordTag}>{keyword}</span>
          ))}
        </div>
      ),
      wrap: true
    },
    {
      name: 'Score',
      selector: row => row.score,
      sortable: true,
      cell: row => (
        <div className={`${styles.scoreCell} ${row.score >= 80 ? styles.highScore :
          row.score >= 50 ? styles.mediumScore :
            styles.lowScore
          }`}>
          {row.score || 'N/A'}
        </div>
      ),
      center: true,
      width: '100px'
    },
    {
      name: 'Actions',
      cell: row => (
        <div className={styles.actionButtons}>
          {/* <button
            onClick={() => {
              setEditingAd(row);
              setUpdateAd(true);
              setIsAdEditorOpen(true);
            }}
            className={styles.editButton}
            title="Edit"
          >
            Edit
          </button> */}
          <button
            onClick={() => handleDeleteAd(campaignName,row.ad_id)}
            className={styles.deleteButton}
            title="Delete"
          >
            Delete
          </button>
          <button
            onClick={() => handleStates(row.ad_id)}
            className={styles.deleteButton}
            title="Delete"
          >
            Stats
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '180px'
    }
  ];

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <div className={styles.searchContainer}>
        <input
          type="text"
          placeholder="Search by abstract or keywords"
          value={filterText}
          onChange={e => setFilterText(e.target.value)}
          className={styles.searchInput}
        />
        <button onClick={handleClear} className={styles.clearButton}>
          Clear
        </button>

      </div>
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={modalStyles}
        contentLabel="Ads Grid Modal"
        closeTimeoutMS={300}
      >
        <div className={styles.modalHeader}>
          <div className={styles.headerContent}>
            <h2>Ads for #{campaignName}</h2>
            <div className={styles.headerActions}>
              <button
                onClick={() => {
                  setEditingAd(null);
                  setIsAdEditorOpen(true);
                }}
                className={styles.newAdButton}
              >
                <span>+ New Ad</span>
              </button>
              <button
                onClick={onClose}
                className={styles.closeButton}
                aria-label="Close"
              >
                &times;
              </button>
            </div>
          </div>
        </div>

        <div className={styles.modalBody}>
          {isLoading ? (
            <div className={styles.loadingContainer}>
              <div className={styles.loadingSpinner}>
                <div className={styles.spinner}></div>
                <p>Loading ads...</p>
              </div>
            </div>
          ) : error ? (
            <div className={styles.errorContainer}>
              <div className={styles.errorContent}>
                <span className={styles.errorIcon}>⚠️</span>
                <p>Error: {error}</p>
              </div>
            </div>
          ) : (

            <DataTable
              columns={columns}
              data={filteredAds}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}  // Do NOT include 25 or more
              subHeader
              subHeaderComponent={subHeaderComponent}
              persistTableHead
              highlightOnHover
              responsive
              customStyles={{
                head: { style: { backgroundColor: '#f8f9fa' } },
                headCells: {
                  style: {
                    fontWeight: '600',
                    fontSize: '14px',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                  },
                },
                cells: {
                  style: {
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    whiteSpace: 'normal',
                    wordBreak: 'break-word',
                  },
                },
              }}
            />
          )}
        </div>
      </Modal>

      <AdEditorPopup
        isOpen={isAdEditorOpen}
        onClose={() => setIsAdEditorOpen(false)}
        campaignName={campaignName}
        adData={editingAd}
        
        onSave={handleSaveAd}
      />
      <AdDistributionModal
        isOpen={adstat}
        adId={selectedAdId}
        onClose={() => setAdStat(false)}
      />
    </>

  );
};

export default AdsGridModal;