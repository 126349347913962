// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Button.module.css */
.Button_buttons__gQENt {
    display: flex;
    gap: 10px;
    margin: 20px 0;
    flex-wrap: wrap;
}

.Button_button__6Y1h1 {
    padding: 8px 16px;
    border: none;
    border-radius: 6px;
    background-color: #f0f0f0;
    color: #333;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.Button_button__6Y1h1:hover {
    background-color: #e0e0e0;
}

.Button_button__6Y1h1:active {
    transform: scale(0.98);
}

/* Active state (optional) */
.Button_buttonActive__yQQ-H {
    background-color: #007bff;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/Button.module.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,aAAa;IACb,SAAS;IACT,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA,4BAA4B;AAC5B;IACI,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":["/* Button.module.css */\n.buttons {\n    display: flex;\n    gap: 10px;\n    margin: 20px 0;\n    flex-wrap: wrap;\n}\n\n.button {\n    padding: 8px 16px;\n    border: none;\n    border-radius: 6px;\n    background-color: #f0f0f0;\n    color: #333;\n    font-weight: 500;\n    cursor: pointer;\n    transition: all 0.2s ease;\n}\n\n.button:hover {\n    background-color: #e0e0e0;\n}\n\n.button:active {\n    transform: scale(0.98);\n}\n\n/* Active state (optional) */\n.buttonActive {\n    background-color: #007bff;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": `Button_buttons__gQENt`,
	"button": `Button_button__6Y1h1`,
	"buttonActive": `Button_buttonActive__yQQ-H`
};
export default ___CSS_LOADER_EXPORT___;
