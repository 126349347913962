
// import React, { useState } from 'react';
// import { Link } from "react-router-dom";
// import styles from '../home.module.css';
import NavBar from '../components/Navbar.js'
// import { 
//   FiCode, 
//   FiSearch, 
//   FiUploadCloud, 
//   FiBarChart2, 
//   FiLink,
//   FiMenu,
//   FiX,
//   FiHome
// } from 'react-icons/fi';
const Home = () => {
  // const [menuOpen, setMenuOpen] = useState(false);
 

  return (
    // <header className={styles.header}>
    //   <div className={styles.headerContainer}>
    //     <h1 className={styles.pageTitle}>
    //       <span className={styles.titleGradient}>Ad Tracking System</span>
    //     </h1>
        
    //     <nav className={styles.navbar}>
    //       {/* Mobile menu toggle */}
    //       <button 
    //         className={styles.mobileMenuButton}
    //         onClick={() => setMenuOpen(!menuOpen)}
    //         aria-label="Toggle menu"
    //         aria-expanded={menuOpen}
    //       >
    //         {menuOpen ? (
    //           <FiX className={styles.menuIcon} />
    //         ) : (
    //           <FiMenu className={styles.menuIcon} />
    //         )}
    //       </button>
          
    //       {/* Complete Navigation Menu */}
    //       <ul className={`${styles.navMenu} ${menuOpen ? styles.active : ''}`}>
    //         <li className={styles.navItem}>
    //           <Link 
    //             to="/" 
    //             className={styles.navLink}
    //             onClick={() => setMenuOpen(false)}
    //           >
    //             <FiHome className={styles.navIcon} />
    //             <span>Home</span>
    //           </Link>
    //         </li>
            
    //         <li className={styles.navItem}>
    //           <Link 
    //             to="/script" 
    //             className={styles.navLink}
    //             onClick={() => setMenuOpen(false)}
    //           >
    //             <FiCode className={styles.navIcon} />
    //             <span>Script Page</span>
    //           </Link>
    //         </li>
            
    //         <li className={styles.navItem}>
    //           <Link 
    //             to="/search" 
    //             className={styles.navLink}
    //             onClick={() => setMenuOpen(false)}
    //           >
    //             <FiSearch className={styles.navIcon} />
    //             <span>Search Page</span>
    //           </Link>
    //         </li>
            
    //         <li className={styles.navItem}>
    //           <Link 
    //             to="/ad-upload" 
    //             className={styles.navLink}
    //             onClick={() => setMenuOpen(false)}
    //           >
    //             <FiUploadCloud className={styles.navIcon} />
    //             <span>Ad Upload</span>
    //           </Link>
    //         </li>
            
    //         <li className={styles.navItem}>
    //           <Link 
    //             to="/dashboard" 
    //             className={styles.navLink}
    //             onClick={() => setMenuOpen(false)}
    //           >
    //             <FiBarChart2 className={styles.navIcon} />
    //             <span>Dashboard</span>
    //           </Link>
    //         </li>
            
    //         <li className={styles.navItem}>
    //           <Link 
    //             to="/page-url-dashboard" 
    //             className={styles.navLink}
    //             onClick={() => setMenuOpen(false)}
    //           >
    //             <FiLink className={styles.navIcon} />
    //             <span>URL Dashboard</span>
    //           </Link>
    //         </li>
    //       </ul>
    //     </nav>
    //   </div>
    // </header>
    <NavBar title="Ad Tracking System"/>
  );
};

export default Home;