import React, { useState, useEffect } from "react";
import AdMetricsDashboard from "./AdMetricsDashboard";

import axios from "axios";
import {
  LineChart, Line, BarChart, Bar, PieChart, Pie, Cell,
  XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, Legend
} from "recharts";
import "../style1.css";
import NavBar from './Navbar.js'
import styles from '../Button.module.css';



const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

const Dashboard = () => {
  const [trackingData, setTrackingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [days, setDays] = useState(null);
  const [activeButton, setActiveButton] = useState('all');
  const [timespent, setTime] = useState({
    day: null, minutes: null, hours: null
  })

  useEffect(() => {
    fetchTrackingData(days);
  }, [days]);

  const fetchTrackingData = async (days = null) => {  // Default to null if not provided
    try {
      setLoading(true);
      setErrorMessage("");

      // Construct query params object (only include 'days' if provided)
      const params = {};
      if (days !== null) {
        params.days = days;
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/track`, {
        params,  // Pass the query parameters
      });

      setTrackingData(response.data);
    } catch (error) {
      console.error("Error fetching tracking data:", error);
      setErrorMessage("Failed to load tracking data. Please check the server.");
    } finally {
      setLoading(false);
    }
  };


  // Compute Dashboard Stats
  const totalSessions = trackingData.length;
  const totalClicks = trackingData.reduce((sum, item) =>
    sum + (item.activityLog?.reduce((a, b) => a + (b.clicks || 0), 0) || 0), 0
  );
  const timeBreakdown = trackingData.reduce((sum, item) =>
    sum + (item.activityLog?.reduce((a, b) => a + parseFloat(Number(b.timeSpent) || 0), 0) || 0), 0
  );



  const remainingHours = (Number(timeBreakdown) / 3600);
  const hours = Math.floor(remainingHours);
  const minutes = Math.round((remainingHours - hours) * 60);



  // Clicks Over Time (Line Chart)
  const clicksOverTime = trackingData.map(item => ({
    name: new Date(item.startTime).toLocaleDateString(),
    clicks: item.activityLog?.reduce((sum, log) => sum + (log.clicks || 0), 0) || 0,
    timeSpent: item.activityLog?.reduce((sum, log) => sum + parseFloat(log.timeSpent || 0), 0) || 0
  }));

  // Clicks by Browser (Bar Chart)
  const browserData = trackingData.reduce((acc, item) => {
    const browser = item.browser || "Unknown";
    acc[browser] = (acc[browser] || 0) + item.activityLog.reduce((sum, log) => sum + (log.clicks || 0), 0);
    return acc;
  }, {});
  const browserChartData = Object.keys(browserData).map(browser => ({ name: browser, clicks: browserData[browser] }));

  // Clicks by OS (Bar Chart)
  const osData = trackingData.reduce((acc, item) => {
    const os = item.operatingSystem || "Unknown";
    acc[os] = (acc[os] || 0) + item.activityLog.reduce((sum, log) => sum + (log.clicks || 0), 0);
    return acc;
  }, {});
  const osChartData = Object.keys(osData).map(os => ({ name: os, clicks: osData[os] }));

  // Time Spent by Connection Type (Pie Chart)
  const connectionTypeData = trackingData.reduce((acc, item) => {
    const connection = item.connectionType || "Unknown";
    const timeSpent = item.activityLog.reduce((sum, log) => sum + parseFloat(log.timeSpent || 0), 0);
    acc[connection] = (acc[connection] || 0) + timeSpent;
    return acc;
  }, {});
  const connectionTypeChartData = Object.keys(connectionTypeData).map(connection => ({
    name: connection,
    value: connectionTypeData[connection]
  }));

  // Top Active Users by Time Spent
  const topUsersByTime = trackingData
    .map(item => ({
      sessionId: item.sessionId,
      timeSpent: item.activityLog.reduce((sum, log) => sum + parseFloat(log.timeSpent || 0), 0)
    }))
    .sort((a, b) => b.timeSpent - a.timeSpent)
    .slice(0, 5);

  // Time Spent by Country
  const countryTimeData = trackingData.reduce((acc, item) => {
    const country = item.country || "Unknown";
    const timeSpent = item.activityLog.reduce((sum, log) => sum + parseFloat(log.timeSpent || 0), 0);
    acc[country] = (acc[country] || 0) + timeSpent;
    return acc;
  }, {});
  const countryTimeChartData = Object.keys(countryTimeData).map(country => ({
    name: country,
    timeSpent: countryTimeData[country]
  }));

  // Device Distribution
  const deviceData = trackingData.reduce((acc, item) => {
    const device = item.device || "Unknown";
    acc[device] = (acc[device] || 0) + 1;
    return acc;
  }, {});
  const deviceChartData = Object.keys(deviceData).map(device => ({
    name: device,
    sessions: deviceData[device]
  }));


  // Helper function to handle button clicks
  const handleButtonClick = (daysValue, buttonName) => {
    setDays(daysValue);
    setActiveButton(buttonName);
  };


  return (
    <>
      <NavBar title="Tracking Dashboard" />
      <div className="dashboard-container">
        <div className={styles.buttons}>
          <button
            className={activeButton === 'today' ? styles.buttonActive : styles.button}
            onClick={() => handleButtonClick(0, 'today')}
          >
            Today
          </button>
          <button
            className={activeButton === 'yesterday' ? styles.buttonActive : styles.button}
            onClick={() => handleButtonClick(1, 'yesterday')}
          >
            Yesterday
          </button>
          <button
            className={activeButton === 'week' ? styles.buttonActive : styles.button}
            onClick={() => handleButtonClick(7, 'week')}
          >
            Week
          </button>
          <button
            className={activeButton === 'month' ? styles.buttonActive : styles.button}
            onClick={() => handleButtonClick(30, 'month')}
          >
            This Month
          </button>
          <button
            className={activeButton === 'all' ? styles.buttonActive : styles.button}
            onClick={() => handleButtonClick(null, 'all')}
          >
            All
          </button>
        </div>

        {errorMessage && (
          <div className="error-message">
            {errorMessage}
          </div>
        )}

        {loading ? (
          <div className="loading-spinner">
            <div className="spinner"></div>
          </div>
        ) : (
          <>
            {/* Metrics Section */}
            <div className="metrics-navbar">
              <div className="metric-card bg-blue">
                <h3>Total Sessions</h3>
                <p>{totalSessions}</p>
              </div>
              <div className="metric-card bg-green">
                <h3>Total Clicks</h3>
                <p>{totalClicks}</p>
              </div>
              <div className="metric-card bg-purple">
                <h3>Total Time Spent</h3>
                <p>

                  {hours > 0 && <span>{hours}h </span>}
                  <span>{minutes}m</span>




                </p>
              </div>
              <div className="metric-card bg-orange">
                <h3>Unique Countries</h3>
                <p>{new Set(trackingData.map(item => item.country)).size}</p>
              </div>
            </div>

            {/* Main Graphs Section */}
            <div className="graph-container">
              {/* Row 1 */}
              <div className="graph-row">
                <div className="graph-card">
                  <h3>Clicks & Time Over Time</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={clicksOverTime}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis yAxisId="left" />
                      <YAxis yAxisId="right" orientation="right" />
                      <Tooltip />
                      <Legend />
                      <Line
                        yAxisId="left"
                        type="monotone"
                        dataKey="clicks"
                        stroke="#8884d8"
                        activeDot={{ r: 8 }}
                      />
                      <Line
                        yAxisId="right"
                        type="monotone"
                        dataKey="timeSpent"
                        stroke="#82ca9d"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>

                <div className="graph-card">
                  <h3>Time Spent by Connection Type</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={connectionTypeChartData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        nameKey="name"
                        label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                      >
                        {connectionTypeChartData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip formatter={(value) => [`${value} seconds`, "Time Spent"]} />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>

              {/* Row 2 */}
              <div className="graph-row">
                <div className="graph-card">
                  <h3>Clicks by Browser</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={browserChartData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="clicks" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>

                <div className="graph-card">
                  <h3>Clicks by OS</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={osChartData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="clicks" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>

              {/* Row 3 */}
              <div className="graph-row">
                <div className="graph-card">
                  <h3>Time Spent by Country</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={countryTimeChartData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip formatter={(value) => [`${value} seconds`, "Time Spent"]} />
                      <Bar dataKey="timeSpent" fill="#ff7300" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>

                <div className="graph-card">
                  <h3>Device Distribution</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={deviceChartData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="sessions"
                        nameKey="name"
                        label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                      >
                        {deviceChartData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>

              {/* Row 4 */}
              <div className="graph-row">
                <div className="graph-card">
                  <h3>Top Users by Time Spent</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={topUsersByTime}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="sessionId" />
                      <YAxis />
                      <Tooltip formatter={(value) => [`${value} seconds`, "Time Spent"]} />
                      <Bar dataKey="timeSpent" fill="#ff4081" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>

                <div className="graph-card">
                  <h3>System Info Distribution</h3>
                  <div className="system-info-grid">
                    <div className="system-info-item">
                      <h4>Average RAM</h4>
                      <p>
                        {(trackingData.reduce((sum, item) => {
                          const ramValue = parseFloat(item.ram) || 0;
                          return sum + ramValue;
                        }, 0) / (trackingData.length || 1)).toFixed(2)} GB
                      </p>
                    </div>
                    <div className="system-info-item">
                      <h4>Most Common CPU</h4>
                      <p>
                        {Object.entries(
                          trackingData.reduce((acc, item) => {
                            const cpu = item.cpuModel || "Unknown";
                            acc[cpu] = (acc[cpu] || 0) + 1;
                            return acc;
                          }, {})
                        ).sort((a, b) => b[1] - a[1])[0]?.[0] || "Unknown"}
                      </p>
                    </div>
                    <div className="system-info-item">
                      <h4>Most Common Connection</h4>
                      <p>
                        {Object.entries(
                          trackingData.reduce((acc, item) => {
                            const conn = item.connectionType || "Unknown";
                            acc[conn] = (acc[conn] || 0) + 1;
                            return acc;
                          }, {})
                        ).sort((a, b) => b[1] - a[1])[0]?.[0] || "Unknown"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <AdMetricsDashboard/>
      
    </>
  );
};
export default Dashboard;