import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Button, InputGroup, FormControl } from "react-bootstrap";
import Modal from "react-modal";
import styles from "../DataTableModal.module.css";
import downloadExcel from "./DownloadXlsx.js"; // Import the downloadExcel function
const modalStyles = {
  overlay: {
    zIndex: 10000,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    width: '90%',
    maxWidth: '1200px',
    maxHeight: '90vh',
    border: 'none',
    borderRadius: '8px',
    padding: '0',
    overflow: 'auto', // <-- change this from 'hidden' to 'auto'
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
  }
};
// Set app element for react-modal (required for accessibility)
Modal.setAppElement('#root'); // Or your app's root element ID

const DataTableModal = ({ showModal, handleClose, llmDomainData, searchDomainData }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedData, setSelectedData] = useState(null);

    // Columns configuration
    const columns = [
        {
            name: "Domain Name",
            selector: (row) => row.domain,
            sortable: true,
        },
        {
            name: "Matches",
            selector: (row) => row.count,
            sortable: true,
        },
    ];

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredData = (data) => {
        return data.filter(
            (item) =>
                item.domain.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.count.toString().includes(searchQuery)
        );
    };

    const handleRowExpand = (row) => {
        setSelectedData(row);
    };

    const handleCloseExpand = () => {
        setSelectedData(null);
    };

    // Convert domain data object to an array of rows
    const convertDomainData = (data) => {
        return Object.keys(data).map((domain) => ({
            domain,
            count: data[domain].count,
            urls: data[domain].urls,
        }));
    };
    const convertToSheetData = (data) => {
        const sheetData = [];
        let totalMatches = 0;

        Object.keys(data).forEach((domain) => {
            const { count, urls } = data[domain];
            sheetData.push({
                domain,
                count,
                urls,
            });
            totalMatches += count;
        });

        return totalMatches ;
    };

    return (
        <>
            {/* Main Modal using react-modal */}
            <Modal
                    isOpen={showModal}
                    onRequestClose={handleClose}
                    style={modalStyles}
                    
                    closeTimeoutMS={300}
                  >
           
                <div className={styles.modalHeader}>
                    <h2 className={styles.modalTitle}>Domain Data</h2>
                    <button 
                onClick={() => downloadExcel(llmDomainData, searchDomainData)} 
                className="btn btn-primary"
            >
                Download Excel
            </button>
                    <button 
                        onClick={handleClose} 
                        className={styles.modalCloseButton}
                        aria-label="Close"
                    >
                        &times;
                    </button>
                </div>
                <div className={styles.modalBody}>
                    <InputGroup className={`${styles.searchInputGroup} mb-3`}>
                        <FormControl
                            className={styles.searchInput}
                            placeholder="Search"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </InputGroup>
                    
                    <div className={styles.dataTableContainer}>
                        <h4>LLM Domain Data</h4>
                        <h5>total matches: {convertToSheetData(llmDomainData)}</h5>
                        <DataTable
                            columns={columns}
                            data={filteredData(convertDomainData(llmDomainData))}
                            expandableRows
                            expandableRowsComponent={({ data }) => (
                                <div className={styles.expandedRowContent}>
                                    <ul>
                                        {data.urls.map((url, index) => (
                                            <li key={index}>
                                                <a href={url} target="_blank" rel="noopener noreferrer">
                                                    {url}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            onRowClicked={handleRowExpand}
                            pagination
                            highlightOnHover
                            striped
                            pointerOnHover
                        />
                    </div>

                    <div className={styles.dataTableContainer}>
                        <h4>Search Domain Data</h4>
                        <h5>total matches: {convertToSheetData(searchDomainData)}</h5>
                        <DataTable
                            columns={columns}
                            data={filteredData(convertDomainData(searchDomainData))}
                            expandableRows
                            expandableRowsComponent={({ data }) => (
                                <div className={styles.expandedRowContent}>
                                    <ul>
                                        {data.urls.map((url, index) => (
                                            <li key={index}>
                                                <a href={url} target="_blank" rel="noopener noreferrer">
                                                    {url}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            onRowClicked={handleRowExpand}
                            pagination
                            highlightOnHover
                            striped
                            pointerOnHover
                        />
                    </div>
                </div>
            </Modal>

            {selectedData && (
                <Modal
                    isOpen={!!selectedData}
                    onRequestClose={handleCloseExpand}
                    className={styles.nestedModalContent}
                    overlayClassName={styles.modalOverlay}
                    closeTimeoutMS={300}
                >
                    <div className={styles.modalHeader}>
                        <h2 className={styles.modalTitle}>{selectedData.domain}</h2>
                        <button 
                            onClick={handleCloseExpand} 
                            className={styles.modalCloseButton}
                            aria-label="Close"
                        >
                            &times;
                        </button>
                    </div>
                    <div className={styles.modalBody}>
                        <ul className={styles.nestedModalBody}>
                            {selectedData.urls.map((url, index) => (
                                <li key={index}>
                                    <a href={url} target="_blank" rel="noopener noreferrer">
                                        {url}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={styles.modalFooter}>
                        <Button variant="secondary" onClick={handleCloseExpand}>
                            Close
                        </Button>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default DataTableModal;