import React, { useState, useEffect } from 'react';
import { Table, Spin, message } from 'antd'; // Using Ant Design for better UI

const AdMetricsDashboard = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAdMetrics = async () => {
      try {
        setLoading(true);
        const response = await fetch('https://api2.novum360.ai/ad-metrics');
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const result = await response.json();
        
        if (result.success) {
          setData(result.data);
        } else {
          throw new Error(result.error || 'Failed to fetch ad metrics');
        }
      } catch (err) {
        console.error('Fetch error:', err);
        setError(err.message);
        message.error('Failed to load ad metrics data');
      } finally {
        setLoading(false);
      }
    };

    fetchAdMetrics();
  }, []);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
        <Spin size="large" tip="Loading ad metrics..." />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ padding: '20px', color: 'red' }}>
        <h2>Error Loading Data</h2>
        <p>{error}</p>
        <button onClick={() => window.location.reload()}>Retry</button>
      </div>
    );
  }

  if (!data) {
    return <div style={{ padding: '20px' }}>No data available</div>;
  }

  // Process the data to create rows for the table
  const tableData = Object.entries(data).map(([pageUrl, pageData]) => {
    // Filter ads that start with https and are not "Unknown Ad"
    const httpsAds = pageData.ads.filter(ad => 
      typeof ad.imageUrl === 'string' && 
      ad.imageUrl.startsWith('https') && 
      ad.imageUrl !== 'Unknown Ad'
    );
    
    return {
      key: pageUrl,
      pageUrl,
      imageUrlCount: httpsAds.length,
      displays: pageData.totals.displays,
      clicks: pageData.totals.clicks,
      loads: pageData.totals.loads,
      rawAds: pageData.ads // Keep original ads for potential expansion
    };
  });

  const columns = [
    {
      title: 'Page URL',
      dataIndex: 'pageUrl',
      key: 'pageUrl',
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer" style={{ wordBreak: 'break-all' }}>
          {text}
        </a>
      ),
      sorter: (a, b) => a.pageUrl.localeCompare(b.pageUrl),
    },
    {
      title: 'Valid Ads Count',
      dataIndex: 'imageUrlCount',
      key: 'imageUrlCount',
      sorter: (a, b) => a.imageUrlCount - b.imageUrlCount,
    },
    {
      title: 'Total Displays',
      dataIndex: 'displays',
      key: 'displays',
      sorter: (a, b) => a.displays - b.displays,
    },
    {
      title: 'Total Clicks',
      dataIndex: 'clicks',
      key: 'clicks',
      sorter: (a, b) => a.clicks - b.clicks,
    },
    {
      title: 'Total Loads',
      dataIndex: 'loads',
      key: 'loads',
      sorter: (a, b) => a.loads - b.loads,
    },
  ];

  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <h2 style={{ marginBottom: '20px' }}>Ad Metrics Dashboard</h2>
      <div style={{ marginBottom: '20px' }}>
        <p>Showing metrics for ads with valid HTTPS URLs (excluding "Unknown Ad")</p>
      </div>
      
      <Table 
        columns={columns} 
        dataSource={tableData} 
        bordered
        pagination={{ pageSize: 10 }}
        scroll={{ x: true }}
        size="middle"
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row style={{ fontWeight: 'bold' }}>
              <Table.Summary.Cell index={0}>Totals</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {tableData.reduce((sum, row) => sum + row.imageUrlCount, 0)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                {tableData.reduce((sum, row) => sum + row.displays, 0)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                {tableData.reduce((sum, row) => sum + row.clicks, 0)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                {tableData.reduce((sum, row) => sum + row.loads, 0)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
        expandable={{
          expandedRowRender: record => (
            <div style={{ margin: 0 }}>
              <h4>All Ads on this Page:</h4>
              <ul>
                {record.rawAds.map((ad, index) => (
                  <li key={index}>
                    <strong>Image URL:</strong> {ad.imageUrl || 'N/A'} | 
                    <strong> Displays:</strong> {ad.displayCount} | 
                    <strong> Clicks:</strong> {ad.clickCount} | 
                    <strong> Loads:</strong> {ad.loadCount}
                  </li>
                ))}
              </ul>
            </div>
          ),
          rowExpandable: record => record.rawAds.length > 0,
        }}
      />
    </div>
  );
};

export default AdMetricsDashboard;