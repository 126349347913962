import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import "./Table.css";
import Spinner from "./Spinner";

const customStyles = {
    headCells: {
        style: {
            backgroundColor: "#1a237e", // Deep blue
            color: "#ffffff",
            fontSize: "1.0625rem",
            fontWeight: 600,
            letterSpacing: "0.5px",
            textTransform: "capitalize",
            padding: "1rem 1.5rem",
            borderBottom: "2px solid #3949ab",
        },
    },
};

function DomainTable() {
    const [domains, setDomains] = useState([]);  // Store domain statistics
    const [records, setRecords] = useState([]);  // Store filtered records
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    // Fetch Data
    useEffect(() => {
        fetchDomainStats();
    }, []);

    const fetchDomainStats = async () => {
        setLoading(true);
        try {
            const response = await axios.get("https://api1.novum360.ai:5000/get_domain_stats"); 
            console.log("API Response:", response.data);

            if (Array.isArray(response.data)) {
                setDomains(response.data);
                setRecords(response.data);
            } else {
                setError("Invalid data format received from API");
            }
        } catch (error) {
            console.error("Error fetching domain stats:", error);
            setError("Failed to fetch domain stats. Please check the server.");
        } finally {
            setLoading(false);
        }
    };

    //Expandable row component -fetch and display urls
    const ExpandedRow = ({ data }) => {
        const [urls, setUrls] = useState([]);
        const [loadingUrls, setLoadingUrls] = useState(false);
        const [currentPage, setCurrentPage] = useState(1);
        const [itemsPerPage, setItemsPerPage] = useState(5);
        // const itemsPerPage = 5;
    
        useEffect(() => {
            setCurrentPage(1);
            fetchUrls(data.domain);
        }, [data.domain]);
    
        const fetchUrls = async (domain) => {
            setLoadingUrls(true);
            try {
                const response = await axios.get(
                    `https://api1.novum360.ai:5000/get_urls_by_domain?domain=${domain}`
                );
                setUrls(response.data);
            } catch (error) {
                console.error("Error fetching URLs:", error);
            } finally {
                setLoadingUrls(false);
            }
        };

        // Pagination logic
        const totalPages = Math.ceil(urls.length / itemsPerPage);
        const paginatedUrls = urls.slice(
            (currentPage - 1) * itemsPerPage,
            currentPage * itemsPerPage
        );
        const handlePrev = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
        const handleNext = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    
        return (
            <div className="expanded-row" >
    
                {loadingUrls ? (
                    <p className="loading-text" >🔄 Loading URLs...</p>
                ) : urls.length > 0 ? (
                    <>
                    <table className="url-table">
                        <thead>
                            <tr>
                                <th>URL</th>
                                <th>Scraped</th>
                                <th>Visit Count</th> {/* 🔹 Fixed column name */}
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedUrls.map((urlData, index) => (
                                <tr key={index}>
                                    <td>{urlData.url ?? "N/A"}</td>
                                    <td>{urlData.scraped === 1 ? "✅ Yes" : "❌ No"}</td>
                                    <td>{urlData.visit_count ?? 0}</td> {/* 🔹 Fixed from count to visit_count */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="pagination-controls">
                    <div className="items-per-page">
                        <label>Rows per page: </label>
                        <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))}>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                        </select>
                    </div>
                                        <button onClick={handlePrev} disabled={currentPage === 1}>
                            ◀ Prev
                        </button>
                        <span>
                            Page {currentPage} of {totalPages}
                        </span>
                        <button onClick={handleNext} disabled={currentPage === totalPages}>
                            Next ▶
                        </button>
                    </div>
                </>


                ) : (
                    <p  className="no-data-text" >
                        No URLs found for this domain.
                    </p>
                )}
            </div>
        );
    };
    





    const columns = [
        { name: "Domain", selector: (row) => row.domain, sortable: true },
        { name: "Total URLs", selector: (row) => row.total_urls, sortable: true },
        { name: "Scraped", selector: (row) => row.scraped, sortable: true },
        { name: "Unscraped", selector: (row) => row.unscraped, sortable: true },
        { name: "Domain Count (Visits)", selector: (row) => row.domain_count, sortable: true },
    ];

    // Handle Search Filter
    const handleSearch = (e) => {
        let query = e.target.value.toLowerCase();
        const filteredRecords = domains.filter((item) => item.domain.toLowerCase().includes(query));
        setRecords(filteredRecords);
    };

    return (
        <div>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <h3>Domain Statistics</h3>
            <Spinner isLoading={loading} />
            <div className="search">
                <input  
                    type="text" 
                    placeholder="Search by Domain" 
                    onChange={handleSearch} 
                />
                <button onClick={fetchDomainStats} disabled={loading} className="refresh-btn">
                    {loading ? "Refreshing..." : "Refresh"}
                </button>
            </div>

            <div className="homeDiv">
                <DataTable 
                    columns={columns} 
                    data={records} 
                    customStyles={customStyles}
                    pagination 
                    highlightOnHover
                    expandableRows
                    expandableRowsComponent={ExpandedRow}
                />
            </div>
        </div>
    );
}

export default DomainTable;
