import React, { useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import Modal from 'react-modal';
import AdsGrid from './AdsGrid';
import styles from '../CampaignList.module.css';
import CampaignUpdate from './EditCampaign.jsx';

const customStyles = {
  header: {
    style: {
      padding: '0',
      marginBottom: '1rem',
    },
  },
  headRow: {
    style: {
      backgroundColor: '#f8f9fa',
      borderBottomWidth: '1px',
      borderBottomColor: '#e9ecef',
      borderBottomStyle: 'solid',
    },
  },
  headCells: {
    style: {
      paddingLeft: '16px',
      paddingRight: '16px',
      fontWeight: '600',
      fontSize: '14px',
      color: '#495057',
    },
  },
  cells: {
    style: {
      paddingLeft: '16px',
      paddingRight: '16px',
      fontSize: '14px',
    },
  },
  rows: {
    style: {
      minHeight: '60px',
      '&:not(:last-of-type)': {
        borderBottomWidth: '1px',
        borderBottomColor: '#e9ecef',
        borderBottomStyle: 'solid',
      },
      '&:hover': {
        backgroundColor: '#f8f9fa',
      },
    },
    highlightOnHoverStyle: {
      backgroundColor: '#f1f3f5',
      transitionDuration: '0.15s',
      transitionProperty: 'background-color',
    },
  },
  pagination: {
    style: {
      borderTopWidth: '1px',
      borderTopColor: '#e9ecef',
      borderTopStyle: 'solid',
    },
  },
};

const CampaignList = ({ campaigns, setCampaigns }) => {
  const [editingCampaignId, setEditingCampaignId] = useState(null);
  const [editCampaignName, setEditCampaignName] = useState('');
  const [modelOpen, setModelOpen] = useState(false);
  const [showAdsModal, setShowAdsModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [campaignToDelete, setCampaignToDelete] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [debouncedFilterText, setDebouncedFilterText] = useState('');



  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFilterText(filterText);
      setResetPaginationToggle(!resetPaginationToggle);
    }, 300); // 300ms delay

    return () => {
      clearTimeout(handler);
    };
  }, [filterText]);

  // Handle upload ads click
  const handleUploadAdsClick = (campaign) => {
    setSelectedCampaign(campaign);
    setShowAdsModal(true);
  };

  // Close ads modal
  const closeAdsModal = () => {
    setShowAdsModal(false);
    setSelectedCampaign(null);
  };

  // Delete campaign
  const deleteCampaign = async (campaignName) => {
    if (window.confirm('Are you sure you want to delete this campaign?')) {
    const url = `${process.env.REACT_APP_API_URL}/delete-campaign`;  // Ensure this matches your backend endpoint
  
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',  // Ensure we're sending JSON data
        },
        body: JSON.stringify({ campaignName })  // Send campaignName in the request body
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      // Handle success
      console.log('Campaign deleted successfully');
      alert(`Campaign "${campaignName}" deleted successfully!`);
      setCampaigns(campaigns.filter(c => c.name !== campaignName));  // Update your UI
      setDeleteModalOpen(false);  // Close the modal after successful delete
    } catch (error) {
      console.error('Error:', error);  // Handle any errors that occur during the request
    }
  }
  };
  

  const filteredCampaigns = useMemo(() => {
    if (!debouncedFilterText) return campaigns;

    return campaigns.filter(item => {
      const searchText = debouncedFilterText.toLowerCase();
      const nameMatch = item.name?.toLowerCase().includes(searchText) || false;
      const dateMatch = item.createdAt?.toLowerCase().includes(searchText) || false;
      return nameMatch || dateMatch;
    });
  }, [campaigns, debouncedFilterText]);

  const ActionsCell = ({ row }) => (
    <div className={styles.actionButtons}>
      <button
        onClick={() => {
          setEditCampaignName(row.name);
          setModelOpen(true);
        }}
        className={styles.editButton}
        title="Edit campaign"
      >
        <svg
          className={styles.buttonIcon}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
          <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
        </svg>
        <span>Edit</span>
      </button>
      <button
        onClick={() => {
          deleteCampaign(row.name);
        }}
        className={styles.deleteButton}
        title="Delete campaign"
      >
        <svg
          className={styles.buttonIcon}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="3 6 5 6 21 6" />
          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
          <line x1="10" y1="11" x2="10" y2="17" />
          <line x1="14" y1="11" x2="14" y2="17" />
        </svg>
        <span>Delete</span>
      </button>
      <button
        onClick={() => handleUploadAdsClick(row)}
        className={styles.uploadButton}
        title="Upload ads"
      >
        <svg
          className={styles.buttonIcon}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
          <polyline points="17 8 12 3 7 8" />
          <line x1="12" y1="3" x2="12" y2="15" />
        </svg>
        <span>Upload</span>
      </button>
    </div>
  );

  const handleSaveEdit = async (id, newName) => {
    if (!newName?.trim()) {
      alert('Campaign name cannot be empty');
      return;
    }
  
    const url = `${process.env.REACT_APP_API_URL}/edit-campaign`;
  
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, name: newName.trim() }),
      });
  
      const result = await response.json();
  
      if (!response.ok) {
        throw new Error(result.error || 'Failed to update campaign');
      }
  
      // Update the campaigns list in state
      setCampaigns((prevCampaigns) =>
        prevCampaigns.map((c) => (c._id === id ? { ...c, name: newName.trim() } : c))
      );
  
      alert('✅ Campaign name updated successfully!');
    } catch (err) {
      console.error('Edit Error:', err);
      alert(`❌ ${err.message}`);
    }
  };
  

  // Table columns configuration
  const columns = [
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
      cell: row =>row.name
    },
    {
      name: 'Created At',
      selector: row => row.createdAt,
      sortable: true,
      format: row => new Date(row.createdAt).toLocaleString()
    },
    {
      name: 'Actions',
      cell: row => <ActionsCell row={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '260px'
    }
  ];



  const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <div className={styles.searchContainer}>
      <div className={styles.searchInputWrapper}>
        <svg
          className={styles.searchIcon}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="11" cy="11" r="8" />
          <line x1="21" y1="21" x2="16.65" y2="16.65" />
        </svg>
        <input
          type="text"
          placeholder="Search campaigns..."
          value={filterText}
          onChange={onFilter}
          className={styles.searchInput}
          autoFocus
        />
        {filterText && (
          <button onClick={onClear} className={styles.clearSearchButton}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </button>
        )}
      </div>
    </div>
  );

  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  const handleClearFilter = () => {
    if (filterText) {
      setFilterText('');
      setDebouncedFilterText('');
      setResetPaginationToggle(!resetPaginationToggle);
    }
  };

  return (
    <div className={styles.campaignsSection}>
      <div className={styles.sectionHeader}>
        <h2 className={styles.sectionTitle}>Recent Campaigns</h2>
        <span className={styles.campaignsCount}>{campaigns.length} campaigns</span>
      </div>

      <DataTable
        title=""
        columns={columns}
        data={filteredCampaigns}
        pagination
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 10, 15, 20]}
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
        subHeaderComponent={
          <FilterComponent
            filterText={filterText}
            onFilter={handleFilterChange}
            onClear={handleClearFilter}
          />
        }
        customStyles={customStyles}
        highlightOnHover
        pointerOnHover
        persistTableHead
        noDataComponent={
          <div className={styles.noData}>
            <svg
              className={styles.noDataIcon}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="10" />
              <line x1="12" y1="8" x2="12" y2="12" />
              <line x1="12" y1="16" x2="12.01" y2="16" />
            </svg>
            <p>No campaigns found</p>
            {filterText && (
              <button onClick={handleClearFilter} className={styles.clearSearchButton}>
                Clear search
              </button>
            )}
          </div>
        }
      />


      {modelOpen && (
        <CampaignUpdate isOpen={modelOpen} onClose={()=>{setModelOpen(false)}}  campaign={editCampaignName}/>
        )}

      {/* Enhanced AdsGrid Modal */}
      <Modal
        isOpen={showAdsModal}
        onRequestClose={closeAdsModal}
        className={styles.modalContent}
        overlayClassName={styles.modalOverlay}
        closeTimeoutMS={200}
      >
        <div className={styles.modalHeader}>
          <h3>Upload Ads for {selectedCampaign?.name}</h3>
          <button
            onClick={closeAdsModal}
            className={styles.modalCloseButton}
            aria-label="Close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </button>
        </div>
        <div className={styles.modalBody}>
          <AdsGrid
            isOpen={showAdsModal}
            onClose={closeAdsModal}
            campaignName={selectedCampaign?.name}
          />
        </div>
      </Modal>

      {/* Enhanced Delete Confirmation Modal */}
      <Modal
        isOpen={deleteModalOpen}
        onRequestClose={() => setDeleteModalOpen(false)}
        className={styles.modalContent}
        overlayClassName={styles.modalOverlay}
        closeTimeoutMS={200}
      >
        <div className={styles.modalHeader}>
          <h3>Confirm Deletion</h3>
          <button
            onClick={() => setDeleteModalOpen(false)}
            className={styles.modalCloseButton}
            aria-label="Close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </button>
        </div>
        <div className={styles.modalBody}>
          <div className={styles.deleteModalContent}>
            <svg
              className={styles.deleteModalIcon}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="10" />
              <line x1="12" y1="8" x2="12" y2="12" />
              <line x1="12" y1="16" x2="12.01" y2="16" />
            </svg>
            <p>Are you sure you want to delete <strong>"{campaignToDelete?.name}"</strong>? This action cannot be undone.</p>
          </div>
          <div className={styles.modalFooter}>
            <button
              onClick={() => setDeleteModalOpen(false)}
              className={styles.modalCancelButton}
            >
              Cancel
            </button>
            <button
              onClick={() => deleteCampaign(campaignToDelete?.name)}
              className={styles.modalDeleteButton}
            >
              Delete Campaign
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CampaignList;