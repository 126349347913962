// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Variables */
:root {
  --primary: #4361ee;
  --primary-light: #4cc9f0;
  --secondary: #3a0ca3;
  --accent: #7209b7;
  --dark: #212529;
  --light: #f8f9fa;
  --gray: #6c757d;
  --light-gray: #e9ecef;
}

/* Base Styles */
.App_appContainer__Q73Qs {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(to bottom, #f5f7fa 0%, #e4e8f0 100%);
}

.App_appContent__Of58b {
  flex: 1 1;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

/* Footer Styles */
.App_footer__aawhi {
  background: var(--dark);
  color: var(--light);
  padding: 1.5rem;
  text-align: center;
  margin-top: auto;
}

.App_footer__aawhi p {
  margin: 0;
  font-size: 0.9rem;
  opacity: 0.8;
}

/* Animation for route transitions */
.App_routeTransition__O2AQv {
  animation: App_fadeIn__AtT-p 0.3s ease;
}

@keyframes App_fadeIn__AtT-p {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Responsive Design */
@media (max-width: 768px) {
  .App_appContent__Of58b {
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .App_appContent__Of58b {
    padding: 1rem;
  }
  
  .App_footer__aawhi {
    padding: 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.module.css"],"names":[],"mappings":"AAAA,cAAc;AACd;EACE,kBAAkB;EAClB,wBAAwB;EACxB,oBAAoB;EACpB,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,qBAAqB;AACvB;;AAEA,gBAAgB;AAChB;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,gEAAgE;AAClE;;AAEA;EACE,SAAO;EACP,WAAW;EACX,iBAAiB;EACjB,cAAc;EACd,kBAAkB;AACpB;;AAEA,kBAAkB;AAClB;EACE,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,iBAAiB;EACjB,YAAY;AACd;;AAEA,oCAAoC;AACpC;EACE,sCAA2B;AAC7B;;AAEA;EACE,OAAO,UAAU,EAAE,2BAA2B,EAAE;EAChD,KAAK,UAAU,EAAE,wBAAwB,EAAE;AAC7C;;AAEA,sBAAsB;AACtB;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":["/* Variables */\n:root {\n  --primary: #4361ee;\n  --primary-light: #4cc9f0;\n  --secondary: #3a0ca3;\n  --accent: #7209b7;\n  --dark: #212529;\n  --light: #f8f9fa;\n  --gray: #6c757d;\n  --light-gray: #e9ecef;\n}\n\n/* Base Styles */\n.appContainer {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  background: linear-gradient(to bottom, #f5f7fa 0%, #e4e8f0 100%);\n}\n\n.appContent {\n  flex: 1;\n  width: 100%;\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 2rem 1rem;\n}\n\n/* Footer Styles */\n.footer {\n  background: var(--dark);\n  color: var(--light);\n  padding: 1.5rem;\n  text-align: center;\n  margin-top: auto;\n}\n\n.footer p {\n  margin: 0;\n  font-size: 0.9rem;\n  opacity: 0.8;\n}\n\n/* Animation for route transitions */\n.routeTransition {\n  animation: fadeIn 0.3s ease;\n}\n\n@keyframes fadeIn {\n  from { opacity: 0; transform: translateY(10px); }\n  to { opacity: 1; transform: translateY(0); }\n}\n\n/* Responsive Design */\n@media (max-width: 768px) {\n  .appContent {\n    padding: 1.5rem;\n  }\n}\n\n@media (max-width: 480px) {\n  .appContent {\n    padding: 1rem;\n  }\n  \n  .footer {\n    padding: 1rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appContainer": `App_appContainer__Q73Qs`,
	"appContent": `App_appContent__Of58b`,
	"footer": `App_footer__aawhi`,
	"routeTransition": `App_routeTransition__O2AQv`,
	"fadeIn": `App_fadeIn__AtT-p`
};
export default ___CSS_LOADER_EXPORT___;
