import React, { useState, useEffect } from "react";
import axios from "axios";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import "../style2.css"; // Import styles
import NavBar from './Navbar.js'
import Table from "./Table.jsx";
import DomainTable from "./DomainTable.jsx";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#A28DFF"];

const PageUrlDashboard = () => {
    const [urls, setUrls] = useState([]);
    const [stats, setStats] = useState({ totalUrls: 0, domainDistribution: [] });
    const [error, setError] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [sortOrder, setSortOrder] = useState("newest");
    const [hoveredUrl, setHoveredUrl] = useState(""); // For live preview
    const [currentPage, setCurrentPage] = useState(1); // Pagination
    const [urlsPerPage] = useState(10); // URLs per page
    const [clickCounts, setClickCounts] = useState({}); // Track URL click counts

    useEffect(() => {
        fetchUrls();
    }, []);

    const fetchUrls = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/page-url`);
            setUrls(response.data.data);
            setStats(response.data.stats);
            setError("");
        } catch (error) {
            console.error("Error fetching URLs:", error);
            setError("Failed to fetch URLs. Please check the server.");
        }
    };

    // Filter URLs based on search query
    const filteredUrls = urls.filter(url => url.url.toLowerCase().includes(searchQuery.toLowerCase()));

    // Sort URLs based on selected order
    const sortedUrls = [...filteredUrls].sort((a, b) => {
        return sortOrder === "newest"
            ? new Date(b.createdAt) - new Date(a.createdAt)
            : new Date(a.createdAt) - new Date(b.createdAt);
    });

    // Pagination Logic
    const indexOfLastUrl = currentPage * urlsPerPage;
    const indexOfFirstUrl = indexOfLastUrl - urlsPerPage;
    const currentUrls = sortedUrls.slice(indexOfFirstUrl, indexOfLastUrl);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Copy URL to clipboard
    const copyToClipboard = (url) => {
        navigator.clipboard.writeText(url);
        alert("Copied to clipboard: " + url);
    };

    // Export URL data as CSV
    const exportToCSV = () => {
        const csvRows = [
            ["URL", "Created At", "Updated At", "Click Count"],
            ...urls.map(url => [
                url.url,
                new Date(url.createdAt).toLocaleString(),
                new Date(url.updatedAt).toLocaleString(),
                clickCounts[url._id] || 0, // Include click count
            ])
        ];

        const csvContent = "data:text/csv;charset=utf-8," + csvRows.map(row => row.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "page_urls.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Track URL clicks
    const handleUrlClick = (urlId) => {
        setClickCounts((prevCounts) => ({
            ...prevCounts,
            [urlId]: (prevCounts[urlId] || 0) + 1,
        }));
    };

    return (
        <>
        <NavBar title="Page URL Dashboard"/>
        <div className="dashboard-container">
            {/* <h1>Page URL Dashboard</h1> */}

            {/* Display Total URLs */}
            <div className="summary-box">
                <h2>Total URLs: {stats.totalUrls}</h2>
            </div>
            <button className="export-button" onClick={exportToCSV}>Export to CSV</button>

            {/* Search Bar */}
            <input
                type="text"
                placeholder="Search URLs..."
                className="search-bar"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />

            {/* Sorting Dropdown */}
            <select className="sort-dropdown" value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
                <option value="newest">Newest First</option>
                <option value="oldest">Oldest First</option>
            </select>

            {/* Export to CSV Button */}

            {/* Error Message */}
            {error && <p className="error-message">{error}</p>}

            <div className="charts-container">
                {/* Bar Chart: URL Counts by Date */}
                <div className="chart">
                    <h3>URLs Added Over Time</h3>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={urls.map(url => ({
                            date: new Date(url.createdAt).toLocaleDateString(),
                            count: 1,
                        }))} margin={{ top: 20, right: 20, left: 20, bottom: 20 }}>
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="count" fill="#8884d8" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>

                {/* Pie Chart: URL Distribution by Domain */}
                <div className="chart">
                    <h3>Domain Distribution</h3>
                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                            <Pie
                                data={stats.domainDistribution}
                                cx="50%"
                                cy="50%"
                                outerRadius={100}
                                fill="#8884d8"
                                dataKey="count"
                                label={({ domain, percent }) => `${domain} (${(percent * 100).toFixed(0)}%)`}
                            >
                                {stats.domainDistribution.map((_, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            </div>
            <DomainTable/>
        </div>

        </>
    );
};

export default PageUrlDashboard;