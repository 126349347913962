import React, { useState, useRef, useEffect } from 'react';
import styles from '../AdEditorPopup.module.css';
import Modal from 'react-modal'
import { v4 as uuidv4 } from 'uuid'
import DataTableModal from './Results';
import Spinner from './Spinner'
const AdEditorPopup = ({
 
        onCancel, isOpen, onClose, onSave, campaignName,
  initialData = {}
}) => {

  const initialFormState = {
    title: '',
    keywords: [],
    keywordInput: '',
    abstract: '',
    score: 50,
    imageFile: null,
    campaignName: campaignName, // Keep this as it might be needed
    previewUrl: null
  };

  const [formData, setFormData] = useState({
    ...initialFormState,
    // Override with initialData if it exists
    ...(initialData && {
      title: initialData.title || '',
      keywords: initialData.keywords || [],
      abstract: initialData.abstract || '',
      score: initialData.score || 50,
      previewUrl: initialData.imageUrl || null
    })
  });
 
  const [results, setResults] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setloading] = useState(false)

  const fileInputRef = useRef(null);
  const resetForm = () => {
    setFormData({
      ...initialFormState,
      campaignName: campaignName // Preserve campaignName if needed
    });
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    setError(null);
    setloading(true)
    // Validate required fields
    if (!formData.keywords.length || !formData.abstract.trim()) {
      setError("Keywords and Abstract are required fields");
      return;
    }

    // Validate score is within valid range (assuming 0-100)
    if (formData.score < 0 || formData.score > 100) {
      setError("Score must be between 0 and 100");
      return;
    }

    try {
      const payload = {
        keywords: formData.keywords.join(', '),
        abstract: formData.abstract,
        score: formData.score / 100 // Convert to 0-1 range if needed
      };

      const response = await fetch(`${process.env.REACT_APP_Flask}/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(
          errorData.message ||
          `Server responded with status ${response.status}`
        );
      }

      const data = await response.json();
      setResults(data);
      setIsModalOpen(true)
      setloading(false)
    } catch (err) {
      console.error("Error fetching search results:", err);
      setError(err.message || "Failed to fetch results. Please try again.");
      setloading(false)
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleKeywordKeyDown = (e) => {
    if (['Enter', 'Tab', ','].includes(e.key) && formData.keywordInput) {
      e.preventDefault();
      setFormData(prev => ({
        ...prev,
        keywords: [...prev.keywords, prev.keywordInput.trim()],
        keywordInput: ''
      }));
    }
  };

  const removeKeyword = (index) => {
    setFormData(prev => ({
      ...prev,
      keywords: prev.keywords.filter((_, i) => i !== index)
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prev => ({
        ...prev,
        imageFile: file,
        previewUrl: URL.createObjectURL(file)
      }));
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };
  useEffect(() => {
    if (initialData && initialData.ad_id) {
      setFormData(prev => ({
        ...prev,
        ad_id: initialData.ad_id, // Only set it if ad_id exists in initialData
      }));
    }
  }, [initialData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const adIdToUse = uuidv4();
    console.log('Ad ID to use:', adIdToUse);
    await onSave({
      ad_id: adIdToUse,
      title: formData.title,
      keywords: formData.keywords,
      abstract: formData.abstract,
      score: formData.score,
      imageFile: formData.imageFile,
      previewUrl: formData.previewUrl,

      campaignName: campaignName
    });
    resetForm();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={styles.modal}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
      closeTimeoutMS={300}
    >
      <div className={styles.popupContainer}>
        <div className={styles.modalHeader}>
          <h2 className={styles.modalTitle}>
            {initialData.ad_id ? 'Edit Ad' : 'Create New Ad'}
            <span className={styles.titleDecoration}></span>
          </h2>
          <button
            onClick={onClose}
            className={styles.closeButton}
            aria-label="Close modal"
          >
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            </svg>
          </button>
        </div>

        <form onSubmit={handleSubmit} className={styles.form}>
          {/* Image Upload Section */}
          <div className={`${styles.formGroup} ${styles.imageUploadGroup}`}>
            <label className={styles.formLabel}>Ad Image</label>
            <div className={styles.imageUploadContainer}>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                accept="image/*"
                className={styles.fileInput}
                id="ad-image-upload"
              />
              {formData.previewUrl ? (
                <div className={styles.imagePreviewWrapper}>
                  <div className={styles.imagePreview}>
                    <img
                      src={formData.previewUrl}
                      alt="Preview"
                      className={styles.previewImage}
                    />
                    <div className={styles.imageOverlay}>
                      <button
                        type="button"
                        onClick={triggerFileInput}
                        className={styles.changeImageButton}
                      >
                        <svg width="20" height="20" viewBox="0 0 24 24">
                          <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                        </svg>
                        Change Image
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <label
                  htmlFor="ad-image-upload"
                  className={styles.uploadPlaceholder}
                >
                  <div className={styles.uploadIcon}>
                    <svg width="48" height="48" viewBox="0 0 24 24">
                      <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                    </svg>
                  </div>
                  <span className={styles.uploadText}>Drag & drop or click to upload</span>
                  <span className={styles.uploadHint}>Recommended size: 1200×628px</span>
                </label>
              )}
            </div>
          </div>

          {/* Title Field */}
          <div className={styles.formGroup}>
            <label htmlFor="ad-title" className={styles.formLabel}>
              Title <span className={styles.optional}>(Optional)</span>
            </label>
            <div className={styles.inputWrapper}>
              <input
                type="text"
                id="ad-title"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                placeholder="Enter ad title"
                className={styles.textInput}
              />
            </div>
          </div>

          {/* Keywords Field */}
          <div className={styles.formGroup}>
            <label className={styles.formLabel}>Keywords</label>
            <div className={styles.keywordsInputContainer}>
              <div className={styles.inputWrapper}>
                <input
                  type="text"
                  value={formData.keywordInput}
                  onChange={(e) => setFormData(prev => ({ ...prev, keywordInput: e.target.value }))}
                  onKeyDown={handleKeywordKeyDown}
                  placeholder="Type keywords and press Enter"
                  className={styles.textInput}
                />
              </div>
              {formData.keywords.length > 0 && (
                <div className={styles.keywordsList}>
                  {formData.keywords.map((keyword, index) => (
                    <span key={index} className={styles.keywordTag}>
                      {keyword}
                      <button
                        type="button"
                        onClick={() => removeKeyword(index)}
                        className={styles.removeKeyword}
                        aria-label={`Remove ${keyword}`}
                      >
                        <svg width="12" height="12" viewBox="0 0 24 24">
                          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                        </svg>
                      </button>
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Abstract Field */}
          <div className={styles.formGroup}>
            <label htmlFor="ad-abstract" className={styles.formLabel}>
              Abstract <span className={styles.required}>*</span>
            </label>
            <div className={styles.inputWrapper}>
              <textarea
                id="ad-abstract"
                name="abstract"
                value={formData.abstract}
                onChange={handleInputChange}
                placeholder="Enter ad description"
                rows={4}
                required
                className={styles.textarea}
              />
            </div>
          </div>

          {/* Score Tracker */}
          <div className={styles.formGroup}>
            <label className={styles.formLabel}>
              Performance Score: <span className={styles.scoreValue}>{formData.score}</span>
            </label>
            <div className={styles.sliderContainer}>
              <input
                type="range"
                name="score"
                min="0"
                max="100"
                value={formData.score}
                onChange={handleInputChange}
                className={styles.scoreSlider}
              />
              <div className={styles.sliderTrack}>
                <div
                  className={styles.sliderProgress}
                  style={{ width: `${formData.score}%` }}
                ></div>
              </div>
              <div className={styles.scoreLabels}>
                <span>0</span>
                <span>50</span>
                <span>100</span>
              </div>
            </div>
          </div>

          {/* Action Buttons */}
          <div className={styles.buttonGroup}>
            <button
              type="button"
              onClick={onClose}
              className={`${styles.button} ${styles.cancelButton}`}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`${styles.button} ${styles.saveButton}`}
              disabled={!formData.abstract || (!formData.previewUrl && !initialData.imageUrl) || !formData.keywords}
            >
              <span>Save Ad</span>
              <svg width="16" height="16" viewBox="0 0 24 24">
                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
              </svg>
            </button>
            <button
              onClick={handleSearch}
              className={`${styles.button} ${styles.saveButton}`}
              disabled={!formData.abstract || !formData.keywords}
            >
              <span>{isLoading ? 'Processing...' : 'Check Reach'}</span>
              <svg width="16" height="16" viewBox="0 0 24 24">
                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
              </svg>

            </button>
            <Spinner isLoading={isLoading} />
          </div>
        </form>
        {error && (
          <h3>{error}</h3>
        )}
        {isModalOpen && (
          
          <DataTableModal
          showModal={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          llmDomainData={results?.llmDomainData}
          searchDomainData={results?.searchDomainData}
      />
        )}

      </div>
    </Modal>

  );
};

export default AdEditorPopup;