import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import ScriptPage from "./components/ScriptPage";
import SearchPage from "./components/SearchPage";
import AdUploadPage from "./components/AdUploadPage";
import AdDisplayPage from "./pages/AdDisplayPage";
import Dashboard from "./components/Dashboard";
import PageUrlDashboard from "./components/PageUrlDashboard";
import styles from './App.module.css';


const App = () => {
  return (
    <Router>
      <div className={styles.appContainer}>
        <div className={styles.appContent}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/script" element={<ScriptPage />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/ad-upload" element={<AdUploadPage />} />
            <Route path="/ad-display" element={<AdDisplayPage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/page-url-dashboard" element={<PageUrlDashboard />} />
          </Routes>
        </div>
        <footer className={styles.footer}>
          <p>© {new Date().getFullYear()} Ad Tracking System. All rights reserved.</p>
        </footer>
      </div>
    </Router>
  );
};

export default App;