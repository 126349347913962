import * as XLSX from 'xlsx';

const downloadExcel = (llmDomainData, searchDomainData) => {
    // Convert domain data into an array of rows suitable for Excel
    const convertToSheetData = (data) => {
        const sheetData = [];
        Object.keys(data).forEach((domain) => {
            const { count, urls } = data[domain];
            urls.forEach((url) => {
                sheetData.push({
                    "Domain Name": domain,
                    "Matches": count,
                    "URL": url,
                });
            });
        });
        return sheetData;
    };

    // Combine both LLM and Search domain data
    const llmSheetData = convertToSheetData(llmDomainData);
    const searchSheetData = convertToSheetData(searchDomainData);

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Add both datasets to the workbook as separate sheets
    const llmSheet = XLSX.utils.json_to_sheet(llmSheetData);
    const searchSheet = XLSX.utils.json_to_sheet(searchSheetData);

    XLSX.utils.book_append_sheet(wb, llmSheet, "LLM Domain Data");
    XLSX.utils.book_append_sheet(wb, searchSheet, "Search Domain Data");

    // Write the workbook to a file
    XLSX.writeFile(wb, "Domain_Data.xlsx");
};

export default downloadExcel;
