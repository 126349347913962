import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import "./Table.css";

const customStyles = {
    headCells: {
        style: {
            backgroundColor: "#1a237e", // Deep blue
            color: "#ffffff",
            fontSize: "1.0625rem",
            fontWeight: 600,
            letterSpacing: "0.5px",
            textTransform: "capitalize",
            padding: "1rem 1.5rem",
            borderBottom: "2px solid #3949ab",
            "&:hover": {
                backgroundColor: "#283593",
            },
        },
    },
};

function Table() {
    const [urls, setUrls] = useState([]);
    const [records, setRecords] = useState([]); // State for filtered data
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false); // Loading state for refresh

    // Fetch Data
    useEffect(() => {
        fetchUrls();
    }, []);

    const fetchUrls = async () => {
        setLoading(true); // Start loading
        try {
            const response = await axios.get("https://api1.novum360.ai:5000/get_url_data");
            
            console.log("API Response:", response.data);
            
            if (response.data && response.data.data) {
                setUrls(response.data.data);
                setRecords(response.data.data); // Initialize records with all data
            } else {
                console.error("Invalid Data Format:", response.data);
                setError("Invalid data format received from API");
            }
        } catch (error) {
            console.error("Error fetching URLs:", error.response ? error.response.data : error.message);
            setError("Failed to fetch URLs. Please check the server.");
        } finally {
            setLoading(false); // Stop loading
        }
    };
    
    const columns = [
        { name: "URL", selector: (row) => row.url, sortable: true },
        { 
            name: "Scraped", 
            selector: (row) => row.scraped ? "True" : "False", 
            sortable: true 
        },
        { name: "Created At", selector: (row) => row.created_at || "N/A" },
        { name: "Last Accessed", selector: (row) => row.last_accessed, sortable: true },
        { name: "Count", selector: (row) => row.count, sortable: true }
    ];
    // Handle Search Filter
    const handleChange = (e) => {
        let query = e.target.value.toLowerCase();
        const newRecords = urls.filter((item) => item.url.toLowerCase().includes(query));
        setRecords(newRecords);
    };

    return (
        <div>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <h3>URL LIST</h3>
            <div className="search">
                <input  
                    type="text" 
                    placeholder="Search by URL" 
                    onChange={handleChange} 
                />
                <button onClick={fetchUrls} disabled={loading} className="refresh-btn">
                    {loading ? "Refreshing..." : "Refresh"}
                </button>
            </div>

            <div className="homeDiv">
                <DataTable 
                    columns={columns} 
                    data={records} 
                    customStyles={customStyles}
                    pagination 
                    highlightOnHover
                />
            </div>
        </div>
    );
}

export default Table;