import AdDisplayPage from "./AdDisplayPage"; // Adjust path if needed

function App() {
    return (
        <div>
            <AdDisplayPage />
        </div>
    );
}

export default App;
