import React, { useState, useEffect } from "react";

import Modal from 'react-modal';
import styles from '../AduploadStyle.module.css';
import CampaignPopup from './CampaignPopup.jsx'
import { FiExternalLink } from "react-icons/fi";
import searchStyles from '../SearchPage.module.css';
import NavBar from './Navbar.js';
import CampaignList from "./CampaignList.jsx";
// Constants
const sentiments = [
  { value: "sadness", label: "😢 Sadness" },
  { value: "anger", label: "😡 Anger" },
  { value: "surprise", label: "😲 Surprise" },
  { value: "joy", label: "😊 Joy" },
  { value: "fear", label: "😨 Fear" }
];

const models = [
  "GPT-4"
];

// Initialize modal (should be done once in your app)
Modal.setAppElement('#root');

const UploadAd = () => {
  // State management
  const [formData, setFormData] = useState({
    keywords: "",
    abstract: "",
    sentiment: "joy",
    accuracy: 67,
    selectedModel: models[0]
  });
 
  const [campaignName, setCampaignName] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const [uiState, setUiState] = useState({
    isSearching: false,
    isUploading: false,
    isLoadingAds: false,
    showAdCampaign: false,
    showSuccessModal: false,
    showDeleteConfirm: false,
    adToDelete: null
  });
 
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    const fetchCampaigns = async () => {
      const url = `${process.env.REACT_APP_API_URL}/get-campaigns`; // Update with your actual API URL
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        // Assuming the API returns an array of campaign objects
        const formattedData = data.map(({ name, campaign_id, createdAt }) => ({
          name,
          campaign_id,
          createdAt
        }));
        setCampaigns(formattedData);
      } catch (error) {
        console.error('Failed to fetch campaigns:', error);
      }
    };

    fetchCampaigns();
  }, []);
  const [dataState, setDataState] = useState({
    uploadStatus: { type: '', message: '' },
    searchResults: null,
    uploadedAds: []
  });

  const toggleAdCampaign = () => {
    setShowPopup(!showPopup);
  };

  const closeSuccessModal = () => {
    setUiState(prev => ({ ...prev, showSuccessModal: false }));
  };

  return (
    <>
      <NavBar title="Ad campaign" />
      <div>
          <button
            onClick={toggleAdCampaign}
            className={styles['campaign-button']}

          >
            {uiState.showAdCampaign ? "Cancel" : "Create Ad Campaign"}
          </button>
       
        {showPopup && (
          
          <CampaignPopup
            isOpen={showPopup}
            onClose={setShowPopup}
            campaignName={campaignName}
            setCampaignName={setCampaignName}
             setCampaigns={setCampaigns}
             campaigns={campaigns}

          />
        )}

        {/* Status Message */}
        {dataState.uploadStatus.message && (
          <p className={`${styles['status-message']} ${styles[dataState.uploadStatus.type]}`}>
            {dataState.uploadStatus.message}
          </p>
        )}
      <CampaignList campaigns={campaigns} setCampaigns={setCampaigns}></CampaignList>

        {/* Success Modal */}
        <Modal
          isOpen={uiState.showSuccessModal}
          onRequestClose={closeSuccessModal}
          className={styles['modal-content']}
          overlayClassName={styles['modal-overlay']}
        >
          <div className={styles['success-modal']}>
            <h3>Success!</h3>
            <p>Your ad has been successfully uploaded.</p>
            <button
              onClick={closeSuccessModal}
              className={styles['modal-button']}
            >
              Close
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default UploadAd;