import React, { useState,useEffect } from 'react';
import styles from '../CampaignPopup.module.css';
import Modal from 'react-modal';
import { v4 as uuidv4 } from 'uuid'
import AdsGrid from './AdsGrid.jsx'

const CampaignPopup = ({
    campaignName: initialCampaignName,
    setCampaignName:parentSetCampaignName,
    onClose,
    isOpen, campaigns, setCampaigns
}) => {
    const [showUpload, setupload] = useState(false)
    const [localCampaignName, setLocalCampaignName] = useState('');
    useEffect(() => {
        if (isOpen) {
            setLocalCampaignName(initialCampaignName || '');
            setError('');
        }
    }, [isOpen, initialCampaignName]);

    var campaign_id = null;
    const save = async (campaignName) => {
        const url = `${process.env.REACT_APP_API_URL}/save-campaign`; // Change to your actual API URL
        campaign_id = uuidv4()
        const data = {
            campaign_id: campaign_id,
            name: campaignName
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const jsonResponse = await response.json();
            console.log('Success:', jsonResponse);
            return jsonResponse;
        } catch (error) {

            console.error('Error:', error);
            return false;
        }
    };
    Modal.setAppElement('#root');
    const [error, setError] = useState('');
    const closeAdsModal = () => {
        setupload(false);
        parentSetCampaignName(false)
    };
    const handleSubmit = async (actionType) => {
        if (!localCampaignName.trim()) {
            setError('Campaign name is required');
            return;
        }

        setError('');
        if (actionType === 'save') {
            const Response = await save(localCampaignName)
            if (Response) { setError('saved success'); console.log("saved"); onClose(false); setCampaigns(prev => [{ name: localCampaignName, campaign_id:campaign_id,createdAt:Date.now() }, ...prev]); } else { setError('already exist'); console.log("not saved"); }
        } else {
            const Response = await save(localCampaignName)
            if (Response) { setError('saved success'); console.log("saved"); setupload(true); setCampaigns(prev => [{ name: localCampaignName, campaign_id:campaign_id,createdAt:Date.now() }, ...prev]); } else { setError('already exist'); console.log("not saved"); }

        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className={styles.modal}
            overlayClassName={styles.overlay}
            contentLabel="Create Campaign Modal"
            closeTimeoutMS={200}
        >
            <div className={styles.modalContent}>
                <div className={styles.header}>
                    <h2>Create New Campaign</h2>
                    <button
                        onClick={()=>onClose(false)}
                        className={styles.closeButton}
                        aria-label="Close"
                    >
                        &times;
                    </button>
                </div>

                <div className={styles.formGroup}>
                    <label htmlFor="campaignName">Campaign Name</label>
                    <input
                        id="campaignName"
                        type="text"
                        value={localCampaignName}
                        onChange={(e) => setLocalCampaignName(e.target.value)}
                        placeholder="Enter campaign name"
                        className={error ? styles.errorInput : ''}
                        autoFocus
                    />
                    {error && <p className={styles.errorText}>{error}</p>}
                </div>

                <div className={styles.buttonGroup}>
                    <button
                        onClick={() => handleSubmit('save')}
                        className={styles.secondaryButton}
                    >
                        Save Campaign
                    </button>
                    <button
                        onClick={() => handleSubmit('saveAndUpload')}
                        className={styles.primaryButton}
                    >
                        Save & Upload Ad
                    </button>
                </div>
            </div>
            {showUpload && (
                <AdsGrid
                    isOpen={showUpload}
                    onClose={closeAdsModal}
                    campaignName={localCampaignName}
                />
            )}
        </Modal>
    );
};

export default CampaignPopup;