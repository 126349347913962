import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import DataTable from 'react-data-table-component';
import styles from '../adstats.module.css'
// Set modal root for accessibility
Modal.setAppElement('#root');

const modalStyles = {
  overlay: {
    zIndex: 9999,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    width: '90%',
    maxWidth: '1200px',
    maxHeight: '90vh',
    border: 'none',
    borderRadius: '8px',
    padding: '0',
    overflow: 'auto', // <-- change this from 'hidden' to 'auto'
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
  }
};
// Custom styles for DataTable
const customTableStyles = {
  headRow: {
    style: {
      backgroundColor: '#f8fafc',
      borderBottomWidth: '1px',
      borderBottomColor: '#e2e8f0',
      borderBottomStyle: 'solid',
    },
  },
  headCells: {
    style: {
      paddingLeft: '16px',
      paddingRight: '16px',
      fontWeight: '600',
      color: '#4a5568',
      fontSize: '14px',
    },
  },
  cells: {
    style: {
      paddingLeft: '16px',
      paddingRight: '16px',
      fontSize: '14px',
    },
  },
  rows: {
    style: {
      minHeight: '60px',
      '&:not(:last-of-type)': {
        borderBottomWidth: '1px',
        borderBottomColor: '#e2e8f0',
        borderBottomStyle: 'solid',
      },
      '&:hover': {
        backgroundColor: '#f8f9fa',
      },
    },
    highlightOnHoverStyle: {
      backgroundColor: '#f1f3f5',
      transitionDuration: '0.15s',
      transitionProperty: 'background-color',
    },
  },
  pagination: {
    style: {
      borderTopWidth: '1px',
      borderTopColor: '#e2e8f0',
      borderTopStyle: 'solid',
    },
  },
};

const AdDistributionModal = ({ isOpen, adId, onClose }) => {
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setAdData] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    console.log('Modal state:', { isOpen, adId });
    const fetchAdData = async () => {
      try {

        setLoading(true);
        setError('');
        const response = await fetch(`${process.env.REACT_APP_API_URL}/ad-distribution/${adId}`);

        if (!response.ok) {
          throw new Error('Failed to fetch ad data');
        }

        const responseData = await response.json();
        setAdData(responseData);
      } catch (err) {
        setError(err.message);
        console.error('Fetch error:', err);
      } finally {
        setLoading(false);
      }
    };

    if (isOpen && adId) {
      fetchAdData();
    }
  }, [isOpen, adId]);

  // Convert object to array for DataTable
  const tableData = data ? Object.entries(data).map(([domain, stats]) => ({
    domain,
    totalUrls: stats.total_urls,
    matchedCount: stats.matched_urls.length,
    matchedUrls: stats.matched_urls
  })) : [];

  // Columns configuration
  const columns = [
    {
      name: <span className="text-gray-700">Domain</span>,
      selector: row => row.domain,
      sortable: true,
      cell: row => (
        <span className="text-gray-900 font-medium truncate">
          {row.domain}
        </span>
      ),
      width: '40%'
    },
    {
      name: <span className="text-gray-700">Total URLs</span>,
      selector: row => row.totalUrls,
      sortable: true,
      cell: row => (
        <span className="text-gray-600">
          {row.totalUrls}
        </span>
      ),
      width: '20%'
    },
    {
      name: <span className="text-gray-700">Matches</span>,
      selector: row => row.matchedCount,
      sortable: true,
      cell: row => (
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
          {row.matchedCount}
        </span>
      ),
      width: '20%'
    }
  ];

  // Expandable row component
  const ExpandedComponent = ({ data }) => (
    <div className="p-4 bg-gray-50 border-t border-gray-200">
      <div className="mb-2">
        <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
          {data.matchedCount} matched URLs
        </span>
      </div>
      <div className="space-y-2 max-h-60 overflow-y-auto pr-2">
        {data.matchedUrls.map((url, index) => (
          <div key={index} className="flex items-start">
            <span className="inline-flex items-center justify-center h-5 w-5 rounded-full bg-gray-200 text-gray-600 text-xs mr-2 mt-0.5">
              {index + 1}
            </span>
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800 hover:underline break-all text-sm"
            >
              {url}
            </a>
          </div>
        ))}
      </div>
    </div>
  );

  // Filtered data based on search input
  const filteredData = tableData.filter(item =>
    item.domain.toLowerCase().includes(filterText.toLowerCase()) ||
    item.totalUrls.toString().includes(filterText) ||
    item.matchedCount.toString().includes(filterText)
  );

  // SubHeader with search filter
  const subHeaderComponent = (
    <div className="flex justify-between items-center px-4 py-3 bg-gray-50 border-b border-gray-200">
      <div className="relative flex-1 max-w-md">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <svg className="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
          </svg>
        </div>
        <input
          type="text"
          placeholder="Search domains or counts..."
          value={filterText}
          onChange={e => {
            setFilterText(e.target.value);
            setResetPaginationToggle(!resetPaginationToggle);
          }}
          className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>
    </div>
  );

  return (
    <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={modalStyles}
        contentLabel="Ads Grid Modal"
        closeTimeoutMS={300}
      >
      <div className={styles.modalContainer}>
        {/* Modal header */}
        <div className={styles.modalHeader}>
          <h3 className={styles.modalTitle}>
            Ad Distribution Report
            {adId && (
              <span className={styles.modalTitleBadge}>
                ID: {adId}
              </span>
            )}
          </h3>
          <button
            onClick={onClose}
            className={styles.closeButton}
          >
            <svg className={styles.closeIcon} fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Search filter */}
        <div className={styles.searchContainer}>
          <div className={styles.searchInputContainer}>
            <svg className={styles.searchIcon} fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
            </svg>
            <input
              type="text"
              placeholder="Search domains or counts..."
              value={filterText}
              onChange={e => {
                setFilterText(e.target.value);
                setResetPaginationToggle(!resetPaginationToggle);
              }}
              className={styles.searchInput}
            />
          </div>
        </div>

        {/* Modal content */}
        <div className={styles.modalContent}>
          {loading ? (
            <div className={styles.loadingContainer}>
              <div className={styles.spinner}></div>
              <p className={styles.loadingText}>Loading data...</p>
            </div>
          ) : error ? (
            <div className={styles.errorContainer}>
              <div className={styles.errorContent}>
                <svg className={styles.errorIcon} fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                Error: {error}
              </div>
              <button
                onClick={onClose}
                className={styles.closeModalButton}
              >
                Close
              </button>
            </div>
          ) : (
            <DataTable
              columns={columns}
              data={filteredData}
              expandableRows
              expandableRowsComponent={ExpandedComponent}
            // ... other DataTable props
            />
          )}
        </div>

        {/* Modal footer */}
        <div className={styles.modalFooter}>
          <button
            onClick={onClose}
            className={styles.closeModalButton}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AdDistributionModal;