import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import styles from '../CampaignUpdate.module.css';


const modalStyles = {
  overlay: {
    zIndex: 10000,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    width: '90%',
    maxWidth: '1200px',
    maxHeight: '90vh',
    border: 'none',
    borderRadius: '8px',
    padding: '0',
    overflow: 'auto', // <-- change this from 'hidden' to 'auto'
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
  }
};
Modal.setAppElement('#root'); // Required for accessibility

const CampaignUpdate = ({ isOpen, onClose, campaign }) => {
  const [newName, setNewName] = useState(campaign || '');
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    // Reset form when campaign prop changes
    setNewName(campaign || '');
    setIsUpdating(false);
    setError('');
  }, [campaign, isOpen]);

  const handleUpdate = async () => {
    if (!newName.trim()) {
      setError('Campaign name cannot be empty');
      return;
    }

    if (newName === campaign) {
      setError('New name must be different');
      return;
    }

    setIsUpdating(true);
    setError('');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/update-campaign-by-name`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          campaign: campaign,  // Note: This matches your endpoint parameter name
          newName: newName    // Note: This matches your endpoint parameter name
        })
      });

      // Check if response is JSON
      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        const text = await response.text();
        throw new Error(text || 'Invalid server response');
      }

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to update campaign');
      }

      console.log("Update successful:", data);
      onClose(true); // Close modal and indicate success
    } catch (err) {
      console.error("Update error:", err);
      setError(err.message.includes('<!DOCTYPE html>') 
        ? 'Server error occurred' 
        : err.message);
    } finally {
      
      setIsUpdating(false);
    }
  };
  console.log(isOpen,"opened")

  return (


    <Modal
      isOpen={isOpen}
      onRequestClose={() => onClose(false)}
      style={modalStyles}
        contentLabel="Ads Grid Modal"
        closeTimeoutMS={300}
    >
      <div className={styles.modalContent}>
  <div className={styles.header}>
    <h2 className={styles.title}>Update Campaign Name</h2>
    <button
      onClick={() => onClose(false)}
      className={styles.closeButton}
      aria-label="Close"
    >
      <svg className={styles.closeIcon} viewBox="0 0 24 24">
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
      </svg>
    </button>
  </div>

  <div className={styles.formGroup}>
    <div className={styles.inputGroup}>
      <label htmlFor="campaignName" className={styles.inputLabel}>Current Name</label>
      <div className={styles.currentNameContainer}>
        <span className={styles.currentName}>{campaign}</span>
        {/* <div className={styles.copyButton} onClick={() => {
          navigator.clipboard.writeText(campaign);
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        }}>
          {copied ? '✓ Copied' : 'Copy'}
        </div> */}
      </div>
    </div>

    <div className={styles.inputGroup}>
      <label htmlFor="newCampaignName" className={styles.inputLabel}>New Name</label>
      <div className={styles.inputContainer}>
        <input
          id="newCampaignName"
          type="text"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          disabled={isUpdating}
          placeholder="Enter new campaign name"
          className={`${styles.inputField} ${error ? styles.errorInput : ''}`}
          autoFocus
        />
        <div className={styles.inputBorder}></div>
      </div>
      {error && (
        <div className={styles.errorContainer}>
          <svg className={styles.errorIcon} viewBox="0 0 24 24">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
          </svg>
          <p className={styles.errorText}>{error}</p>
        </div>
      )}
    </div>
  </div>

  <div className={styles.buttonGroup}>
    <button
      onClick={() => onClose(false)}
      className={`${styles.button} ${styles.secondaryButton}`}
      disabled={isUpdating}
    >
      Cancel
    </button>
    <button
      onClick={handleUpdate}
      disabled={isUpdating || !newName.trim() || newName === campaign}
      className={`${styles.button} ${styles.primaryButton}`}
    >
      {isUpdating ? (
        <>
          <svg className={styles.spinner} viewBox="0 0 50 50">
            <circle cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
          </svg>
          Updating...
        </>
      ) : (
        'Update Campaign'
      )}
    </button>
  </div>
</div>
    </Modal>
  );
};

export default CampaignUpdate;