import React, { useState } from "react";
import styles from '../SearchPage.module.css'; // Import the updated styles
import { FiSearch, FiLink, FiArrowRight, FiExternalLink } from 'react-icons/fi';
import NavBar from './Navbar.js'
import Spinner from './Spinner';
import DataTableModal from './Results.jsx';


const sentiments = [
    { value: "sadness", label: "😢" },
    { value: "anger", label: "😡" },
    { value: "surprise", label: "😲" },
    { value: "joy", label: "😊" },
    { value: "fear", label: "😨" }
];

const models = [
    "gemini-1.5-pro",
    "gemini-2.0-flash-001",
    "gemini-2.0-flash",
    "gemini-2.0-flash-lite"
];

const SearchPage = () => {
    const [keywords, setKeywords] = useState("");
    const [abstract, setAbstract] = useState("");
    //const [sentiment, setSentiment] = useState([]); 
    const [sentiment, setSentiment] = useState(sentiments.map(s => s.value));
    const [accuracy, setAccuracy] = useState(50); // Default midpoint for slider
    const [selectedModel, setSelectedModel] = useState(models[0]);
    const [results, setResults] = useState(null);
    const [error, setError] = useState(null);
    const [url, setUrl] = useState(""); // For the process_url endpoint
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Function to handle the search endpoint
    const handleSearch = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);
        try {
            const payload = {
                keywords,
                abstract,
                score: accuracy / 100,// Map accuracy to a score between 0 and 1
                model: selectedModel,
                sentiment,
            };

            const response = await fetch(`${process.env.REACT_APP_Flask}/search`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error("Failed to fetch search results");
            }

            const data = await response.json();
            setResults(data);
            setIsModalOpen(true)
        } catch (err) {
            console.error("Error fetching search results:", err);
            setError("Failed to fetch results. Please try again.");
        }finally{
            setLoading(false);
        }
    };

    // Function to handle the process_url endpoint
    const handleProcessUrl = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        try {
            const payload = { url };

            const response = await fetch(`${process.env.REACT_APP_Flask}/process_url`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error("Failed to process URL");
            }

            const data = await response.json();
            setResults(data); // Display the processed URL results
        } catch (err) {
            console.error("Error processing URL:", err);
            setError("Failed to process URL. Please try again.");
        }finally{
            setLoading(false)
        }
    };

    const handleSentimentChange=(value)=>{
        setSentiment((prev)=>
            prev.includes(value)
        ? prev.filter((s)=> s!==value)
        :[...prev,value]
    );
    };

    return (
        <>
        <NavBar title="Search"/>
        <div className={styles.pageContainer}>
            {/* Search Form */}
            <Spinner isLoading={loading} />
            <form onSubmit={handleSearch} className={styles.formContainer}>
                <div className={styles.inputGroup}>
                    <label className={styles.inputLabel}>Keywords:</label>
                    <div className={styles.inputWrapper}>
                        <input
                            type="text"
                            value={keywords}
                            onChange={(e) => setKeywords(e.target.value)}
                            className={styles.inputField}
                            required
                            placeholder="Enter keywords..."
                        />
                        <FiSearch className={styles.inputIcon} />
                    </div>
                </div>

            

                <div className={styles.inputGroup}>
                    <label className={styles.inputLabel}>Abstract:</label>
                    <textarea
                        value={abstract}
                        onChange={(e) => setAbstract(e.target.value)}
                        rows="3"
                        className={`${styles.inputField} ${styles.textareaField}`}
                        placeholder="Enter a brief abstract..."
                    />
                </div>


                <div className={styles.inputGroup}>
                    <label className={styles.inputLabel}>Sentiment:</label>
                    <div className={styles.sentimentContainer}>
                        {sentiments.map((s, index) => (
                            <label key={index} className={styles.sentimentOption}>
                                <input
                                    type="checkbox"
                                    name="sentiment"
                                    value={s.value}
                                    checked={sentiment.includes(s.value)}
                                    onChange={() => handleSentimentChange(s.value)}
                                    className={styles.checkboxInput}
                                />
                                <span className={styles.emoji}>{s.label}</span>
                                <span className={styles.sentimentLabel}>{s.value}</span>
                            </label>
                        ))}
                    </div>
                </div>

                <div className={styles.inputGroup}>
                    <label className={styles.inputLabel}>
                        <span>Max Accuracy</span>
                    </label>
                    <div className={styles.sliderContainer}>
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={accuracy}
                            onChange={(e) => setAccuracy(Number(e.target.value))}
                            className={styles.slider}
                            style={{
                                background: `linear-gradient(to right, #4cc9f0 0%, #4cc9f0 ${accuracy}%, #ddd ${accuracy}%, #ddd 100%)`
                            }}
                        />
                        <div className={styles.sliderValue}>
                            <span>{accuracy}%</span>
                            <span>{accuracy < 50 ? "Max Reach" : "Max Accuracy"}</span>
                        </div>
                    </div>
                </div>

                <div className={styles.inputGroup}>
                    <label className={styles.inputLabel}>Model Selection:</label>
                    <div className={styles.selectWrapper}>
                        <select
                            value={selectedModel}
                            onChange={(e) => setSelectedModel(e.target.value)}
                            className={styles.selectField}
                        >
                            {models.map((model, index) => (
                                <option key={index} value={model}>{model}</option>
                            ))}
                        </select>
                        <FiArrowRight className={styles.selectIcon} />
                    </div>
                </div>

                <button type="submit" className={styles.submitButton} disabled={loading}>
                    <FiSearch className={styles.buttonIcon} />
                    Search
                </button>
            </form>

            {/* Process URL Form */}
            <form onSubmit={handleProcessUrl} className={`${styles.formContainer} ${styles.urlForm}`}>
                <div className={styles.inputGroup}>
                    <label className={styles.inputLabel}>Process URL:</label>
                    <div className={styles.inputWrapper}>
                        <input
                            type="text"
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                            className={styles.inputField}
                            placeholder="Enter a URL to process"
                            required
                        />
                        <FiLink className={styles.inputIcon} />
                    </div>
                </div>
                <button type="submit" className={`${styles.submitButton} ${styles.urlButton}`} disabled={loading} >
                    <FiLink className={styles.buttonIcon} />
                    Process URL
                </button>
            </form>

            {/* Error Message */}
            {error && (
                <div className={styles.errorMessage}>
                    <div className={styles.errorContent}>
                        <span className={styles.errorIcon}>!</span>
                        {error}
                    </div>
                </div>
            )}

           
            {results && (
                <div className={styles.resultsContainer}>
                    <h2 className={styles.resultsTitle}>
                        <span className={styles.resultsTitleHighlight}>Results</span>
                    </h2>

                    
                    {results.message && (
                        <div className={styles.resultsMessage}>
                            <strong>Message:</strong> {results.message}
                        </div>
                    )}
                {isModalOpen && (
          
          <DataTableModal
          showModal={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          llmDomainData={results?.llmDomainData}
          searchDomainData={results?.searchDomainData}
      />
        )}
               
{/* {results.llmResult && (
    <div className={styles.resultsSection}>
        <h3 className={styles.resultsSubtitle}>
            <span className={styles.subtitleIcon}>🤖</span>
            LLM Results
            <span className={styles.resultCount}>
                ({results.llmResult.length} {results.llmResult.length === 1 ? 'match' : 'matches'})
            </span>
        </h3>
        <div className={styles.resultsTableContainer}>
            <table className={styles.resultsTable}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>URL</th>
                        <th>Visit</th>
                    </tr>
                </thead>
                <tbody>
                    {results.llmResult.map((result, index) => (
                        <tr key={index} className={styles.resultItem}>
                            <td>{index + 1}</td>
                            <td>
                                <a 
                                    href={result} 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    className={styles.resultLink}
                                >
                                    {new URL(result).hostname}
                                </a>
                            </td>
                            <td>
                                <a 
                                    href={result} 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    className={styles.externalLink}
                                >
                                    <FiExternalLink />
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
    
)} */}


{/* {results.searchResult && (
    <div className={styles.resultsSection}>
        <h3 className={styles.resultsSubtitle}>
            <span className={styles.subtitleIcon}>🔍</span>
            Search Results
            <span className={styles.resultCount}>
                ({results.searchResult.length} {results.searchResult.length === 1 ? 'match' : 'matches'})
            </span>
        </h3>
        <div className={styles.resultsList}>
            {results.searchResult.map((result, index) => (
                <div key={index} className={styles.resultListItem}>
                    <a 
                        href={result} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className={styles.resultLink}
                    >
                        {new URL(result).hostname}
                    </a>
                    <a 
                        href={result} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className={styles.externalLink}
                    >
                        <FiExternalLink />
                    </a>
                </div>
            ))}
        </div>
    </div>
)} */}


{/* {(results.llmResult || results.searchResult) && (
    <div className={styles.resultsSummary}>
        <div className={styles.summaryCard}>
            <h4 className={styles.summaryTitle}>Total Matches</h4>
            <div className={styles.summaryGrid}>
                <div className={styles.summaryItem}>
                    <span className={styles.summaryNumber}>
                        {results.llmResult ? results.llmResult.length : 0}
                    </span>
                    <span className={styles.summaryLabel}>LLM Results</span>
                </div>
                <div className={styles.summaryItem}>
                    <span className={styles.summaryNumber}>
                        {results.searchResult ? results.searchResult.length : 0}
                    </span>
                    <span className={styles.summaryLabel}>Search Results</span>
                </div>
                <div className={styles.summaryItem}>
                    <span className={styles.summaryNumber}>
                        {(results.llmResult ? results.llmResult.length : 0) + 
                         (results.searchResult ? results.searchResult.length : 0)}
                    </span>
                    <span className={styles.summaryLabel}>Total URLs</span>
                </div>
            </div>
        </div>
    </div>
)} */}

                    {/* Processed URL Data */}
                    {results.data && (
                        <div className={styles.resultsSection}>
                            <h3 className={styles.resultsSubtitle}>
                                <span className={styles.subtitleIcon}>📊</span>
                                Processed URL Data
                            </h3>
                            <div className={styles.dataTableContainer}>
                                <table className={styles.dataTable}>
                                    <thead>
                                        <tr>
                                            <th>Domain</th>
                                            <th>Last Accessed</th>
                                            <th>Sentiment</th>
                                            <th>Score</th>
                                            <th>URL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-label="Domain">{results.data.domain}</td>
                                            <td data-label="Last Accessed">{results.data.last_accessed}</td>
                                            <td data-label="Sentiment">
                                                <span className={`${styles.sentimentBadge} ${
                                                    results.data.sentiment === 'positive' ? styles.positive :
                                                    results.data.sentiment === 'negative' ? styles.negative :
                                                    styles.neutral
                                                }`}>
                                                    {results.data.sentiment}
                                                </span>
                                            </td>
                                            <td data-label="Score">{results.data.sentiment_score}</td>
                                            <td data-label="URL">
                                                <a 
                                                    href={results.data.url} 
                                                    target="_blank" 
                                                    rel="noopener noreferrer" 
                                                    className={styles.resultLink}
                                                >
                                                    {new URL(results.data.url).hostname}
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
        </>
    );
};

export default SearchPage;