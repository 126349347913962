import React, { useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { FiCopy, FiSearch, FiClock } from 'react-icons/fi';
import styles from '../scriptHistory.module.css';

const ScriptHistoryTable = ({ scripts }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // Filter scripts based on search query
    const filteredScripts = useMemo(() => {
        if (!searchQuery) return scripts;
        return scripts.filter(item =>
            item.domain.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [scripts, searchQuery]);

    // Pagination
    const totalRows = filteredScripts.length;
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    const paginatedScripts = useMemo(() => {
        const startIndex = (currentPage - 1) * rowsPerPage;
        return filteredScripts.slice(startIndex, startIndex + rowsPerPage);
    }, [filteredScripts, currentPage, rowsPerPage]);

    // Table columns
    const columns = [
        {
            name: 'Domain',
            selector: row => row.domain,
            sortable: true,
            cell: row => (
                <span className={styles.domainBadge}>
                    {row.domain}
                </span>
            ),
            width: '35%'
        },
        {
            name: 'Created Date',
            selector: row => row.timestamp,
            sortable: true,
            cell: row => (
                <span className={styles.timestamp}>
                    {new Date(row.timestamp).toLocaleString()}
                </span>
            ),
            width: '45%'
        },
        {
            name: 'Copy Script',
            cell: row => (
                <button
                    onClick={() => {
                        navigator.clipboard.writeText(row.script);
                        // Optional: Add toast notification here
                    }}
                    className={styles.copyButton}
                    title="Copy script to clipboard"
                >
                    <FiCopy />
                    <span>Copy</span>
                </button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '20%'
        }
    ];

    // Custom styles for the table
    const customStyles = {
        headRow: {
            style: {
                backgroundColor: '#f8f9fa',
                borderBottomWidth: '1px',
                borderBottomColor: '#e9ecef',
                borderBottomStyle: 'solid',
            },
        },
        headCells: {
            style: {
                fontWeight: '600',
                fontSize: '14px',
                paddingLeft: '16px',
                paddingRight: '16px',
            },
        },
        cells: {
            style: {
                paddingLeft: '16px',
                paddingRight: '16px',
            },
        },
        pagination: {
            style: {
                borderTopWidth: '1px',
                borderTopColor: '#e9ecef',
                borderTopStyle: 'solid',
            },
        },
    };

    return (
        <div className={styles.scriptHistoryContainer}>
            <div className={styles.header}>
                <div className={styles.titleSection}>
                    <FiClock className={styles.clockIcon} />
                    <h3>Recent Scripts</h3>
                    <div className={styles.totalScripts}>
                        Total: {scripts.length} script{scripts.length !== 1 ? 's' : ''}
                    </div>
                </div>

                <div className={styles.searchContainer}>
                    <FiSearch className={styles.searchIcon} />
                    <input
                        type="text"
                        placeholder="Search by domain..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className={styles.searchInput}
                    />
                </div>
            </div>

            <DataTable
                columns={columns}
                data={filteredScripts}
                pagination
                paginationPerPage={rowsPerPage}
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                paginationComponentOptions={{
                    rowsPerPageText: 'Rows per page:',
                    rangeSeparatorText: 'of',
                    noRowsPerPage: false,
                }}
                onChangePage={page => setCurrentPage(page)}
                onChangeRowsPerPage={currentRowsPerPage => {
                    setRowsPerPage(currentRowsPerPage);
                    setCurrentPage(1);
                }}
                customStyles={customStyles}
                highlightOnHover
                noDataComponent={
                    <div className={styles.noData}>
                        {searchQuery ? (
                            <>No scripts found matching "<strong>{searchQuery}</strong>"</>
                        ) : (
                            'No scripts available in history'
                        )}
                    </div>
                }
            />
        </div>
    );
};

export default ScriptHistoryTable;